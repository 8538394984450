import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { DropdownButton } from "../Dropdown/Dropdown";
import { ButtonIconAlign, ButtonVariant, IconName, } from "@eightfold.ai/octuple";
import styles from "./ResetButton.module.scss";
import { getFilters, updateFilters } from "../../providers/FilterProvider";
var ResetButton = function (_a) {
    var label = _a.label, onClick = _a.onClick;
    var onScoreTypeSavingClick = function () {
        var _a = getFilters(), scoreType = _a.scoreType, dashboardScoreType = _a.dashboardScoreType;
        onClick && onClick();
        updateFilters({ scoreType: scoreType, dashboardScoreType: dashboardScoreType });
    };
    return (_jsx(DropdownButton, { onClick: onScoreTypeSavingClick, iconOverrideProps: {
            path: IconName.mdiClose,
        }, buttonProps: {
            variant: ButtonVariant.SystemUI,
            counter: "",
        }, alignIcon: ButtonIconAlign.Left, label: label, buttonOverrideProps: { classNames: styles.resetButton } }));
};
export default ResetButton;
