import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./src/reportWebVitals";
import Application from "./src/Application";
import { initialize } from '@sumologic/opentelemetry-rum';
var container = document.getElementById("root");
if (!container) {
    throw "can't find root element";
}
if (process.env.SUMOLOGIC_COLLECTION_SOURCE_URL
    && process.env.SUMOLOGIC_COLLECTION_SERVICE_NAME
    && process.env.SUMOLOGIC_COLLECTION_DEPLOYMENT_ENVIRONMENT
    && process.env.SUMOLOGIC_COLLECTION_APPLICATION_NAME
    && process.env.SUMOLOGIC_COLLECTION_API_URL) {
    var urls = eval(process.env.SUMOLOGIC_COLLECTION_API_URL);
    if (!Array.isArray(urls)) {
        throw new Error('expecting process.env.SUMOLOGIC_COLLECTION_API_URL to be an array');
    }
    if (urls.some(function (url) { return typeof (url) !== 'string' && !(url instanceof RegExp); })) {
        throw new Error('values in the process.env.SUMOLOGIC_COLLECTION_API_URL '
            + 'array must be a string or RegExp');
    }
    initialize({
        collectionSourceUrl: process.env.SUMOLOGIC_COLLECTION_SOURCE_URL,
        serviceName: process.env.SUMOLOGIC_COLLECTION_SERVICE_NAME,
        deploymentEnvironment: process.env.SUMOLOGIC_COLLECTION_DEPLOYMENT_ENVIRONMENT,
        applicationName: process.env.SUMOLOGIC_COLLECTION_APPLICATION_NAME,
        samplingProbability: 1,
        propagateTraceHeaderCorsUrls: urls,
        collectErrors: true,
    });
}
else {
    console.warn("SumoLogic is not running because its environment variables not set");
}
var root = createRoot(container);
root.render(_jsx(Application, {}));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
