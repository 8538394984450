var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useState } from "react";
import { Icon, IconName, Panel, PanelSize, Stack } from "@eightfold.ai/octuple";
import { NineBoxFilter } from "../NineBoxFilter/NineBoxFilter";
import styles from "./Additional.module.scss";
import { AvailableFilters } from "./Filters";
import { MultiSelectFilter } from "./MultiSelectFilter";
import { useGetClientPlatformQuery, useGetDataFiltersQuery, } from "../../store/api/core";
import { usePermissions } from "../../hooks/permissions";
import { haveTalentReviewPages } from "../../common/featureFlags";
import Button from "../Button";
import ResetButton from "../ResetButton";
import { Divider } from "@mui/material";
import { FilterContext, getFilters, updateFilters, } from "../../providers/FilterProvider";
import CheckBox from "../CheckBox";
var getFilterSet = function (filters) {
    return [
        {
            key: AvailableFilters.RetentionRisk,
            implementation: (_jsx(MultiSelectFilter, { entity: "retentionRisk", label: "Retention Risks", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.RetentionRisk)),
        },
        {
            key: AvailableFilters.CareerInterest,
            implementation: (_jsx(MultiSelectFilter, { entity: "careerInterests", label: "Career Interests", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.CareerInterest)),
        },
        {
            key: AvailableFilters.AssessmentSource,
            implementation: (_jsx(MultiSelectFilter, { entity: "assessmentSource", label: "Assessment Source", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.AssessmentSource)),
        },
        {
            key: AvailableFilters.Age,
            implementation: (_jsx(MultiSelectFilter, { useOptionsQuery: useGetDataFiltersQuery, entity: "ageRange", label: "Age" }, AvailableFilters.Age)),
        },
        {
            key: AvailableFilters.DerailerRisk,
            implementation: (_jsx(MultiSelectFilter, { entity: "derailerRisk", label: "Derailer Risks", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.DerailerRisk)),
        },
        {
            key: AvailableFilters.RelocationPreference,
            implementation: (_jsx(MultiSelectFilter, { entity: "willingToRelocate", label: "Relocation Preferences", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.RelocationPreference)),
        },
        {
            key: AvailableFilters.Gender,
            implementation: (_jsx(MultiSelectFilter, { entity: "gender", label: "Gender", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.Gender)),
        },
        {
            key: AvailableFilters.Ethnicity,
            implementation: (_jsx(MultiSelectFilter, { entity: "ethnicity", label: "Ethnicity", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.Ethnicity)),
        },
        {
            key: AvailableFilters.NineBox,
            implementation: _jsx(NineBoxFilter, {}, AvailableFilters.NineBox),
        },
        {
            key: AvailableFilters.NineBoxRating,
            implementation: (_jsx(MultiSelectFilter, { entity: "9boxRatings", label: "9 Box Rating", filterEntity: "nineboxRating", useOptionsQuery: useGetDataFiltersQuery }, AvailableFilters.NineBoxRating)),
        },
    ].filter(function (_a) {
        var key = _a.key;
        return filters.has(key);
    });
};
var Header = function () { return (_jsx("div", __assign({ className: styles.panelHeader }, { children: "Additional Filters" }))); };
var Body = function (_a) {
    var filters = _a.filters;
    var _b = usePermissions(), nineBox = _b.nineBox, showDiversitySpecific = _b.showDiversitySpecific;
    var filtersVisibleValues = useGetClientPlatformQuery({});
    var hasPermission = function (_a) {
        var _b, _c, _d, _e, _f;
        var key = _a.key;
        return !((key == AvailableFilters.Ethnicity && !showDiversitySpecific) ||
            (key == AvailableFilters.Gender && !showDiversitySpecific) ||
            (key == AvailableFilters.NineBox && !(nineBox && haveTalentReviewPages()))) &&
            ((_f = (_e = (_d = (_c = (_b = filtersVisibleValues === null || filtersVisibleValues === void 0 ? void 0 : filtersVisibleValues.data) === null || _b === void 0 ? void 0 : _b.data[0]) === null || _c === void 0 ? void 0 : _c.applications) === null || _d === void 0 ? void 0 : _d.orgDashboard) === null || _e === void 0 ? void 0 : _e.filters) === null || _f === void 0 ? void 0 : _f[key]) !== false;
    };
    var getFiltersData = getFilterSet(filters)
        .filter(hasPermission)
        .map(function (_a) {
        var implementation = _a.implementation;
        return implementation;
    });
    var handleCheckboxChange = function (event) {
        var isChecked = event.target.checked;
        if (isChecked) {
            updateFilters({ hightPotentialLeader: "Yes" });
        }
        else {
            updateFilters({ hightPotentialLeader: undefined });
        }
    };
    return (_jsxs("div", __assign({ className: styles.body }, { children: [_jsx("div", __assign({ className: styles.highPotentialTalentCheckBoxWrapper }, { children: _jsx(CheckBox, { value: getFilters().hightPotentialLeader || "No", label: "See only high potential talent", checked: getFilters().hightPotentialLeader === "Yes", onChange: handleCheckboxChange }) })), _jsx(Stack, __assign({ direction: "vertical", flexGap: "s" }, { children: getFiltersData }))] })));
};
var AdditionalFilterButton = function (_a) {
    var onClick = _a.onClick, counter = _a.counter;
    return (_jsxs("div", __assign({ className: styles.additionalButtonContainer, style: __assign({}, (counter > 0 && {
            backgroundColor: "#EBFDFF",
        })), onClick: onClick }, { children: [counter > 0 && (_jsx("div", __assign({ className: styles.additionalButtonCounter }, { children: counter }))), _jsx(Icon, { path: IconName.mdiTuneVariant }), _jsx("button", __assign({ className: styles.additionalButton }, { children: "Additional Filters" }))] })));
};
export var Additional = function (_a) {
    var filters = _a.filters;
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var _c = useState(0), resetKey = _c[0], setResetKey = _c[1];
    var onClick = function () { return setVisible(function (previous) { return !previous; }); };
    var getFilters = useContext(FilterContext).getFilters;
    var clearFilters = function () {
        updateFilters({
            retentionRisk: [],
            careerInterests: [],
            assessmentSource: [],
            ageRange: [],
            derailerRisk: [],
            willingToRelocate: [],
            gender: [],
            ethnicity: [],
            ninebox_id: undefined,
            hightPotentialLeader: undefined,
            assigned_box: [],
            nineboxRating: [],
        });
        setResetKey(function (prevKey) { return prevKey + 1; });
    };
    var countSelectedAdditionalFilters = function () {
        var currentFilters = getFilters();
        var additionalFilters = new Set([
            "retentionRisk",
            "careerInterests",
            "assessmentSource",
            "ageRange",
            "derailerRisk",
            "willingToRelocate",
            "ninebox_id",
            "assigned_box",
            "nineboxRating",
        ]);
        var selectedCount = 0;
        additionalFilters.forEach(function (key) {
            var value = currentFilters[key];
            if (Array.isArray(value)
                ? value.length > 0
                : value && value !== "No" && value !== "undefined") {
                selectedCount++;
            }
        });
        return selectedCount;
    };
    return (_jsxs(_Fragment, { children: [visible && (_jsxs(Panel, __assign({ placeholder: null, bodyPadding: false, size: PanelSize.small, visible: true, onClose: function () {
                    setVisible(false);
                } }, { children: [_jsx(Header, {}), _jsx(Divider, {}), _jsxs("div", __assign({ className: styles.additionalFilterContainer }, { children: [_jsx(Body, { filters: filters }), _jsxs("div", { children: [_jsx(Divider, {}), _jsxs("div", __assign({ className: styles.additionalFilterButtonsContainer }, { children: [_jsx(Button, { text: "Done", onClick: function () { return setVisible(false); } }), _jsx(ResetButton, { label: "Reset", onClick: clearFilters })] }))] })] }), resetKey)] }))), _jsx(AdditionalFilterButton, { counter: countSelectedAdditionalFilters(), onClick: onClick })] }));
};
