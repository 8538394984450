var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { useTheme, Box, styled } from "@mui/material";
import { Status } from "../../common/action";
import CardTooltip from "../CardTooltip/CardTooltip";
var ScoreCard = function (props) {
    var theme = useTheme();
    var cardColor = props.action === Status.Maintain
        ? theme.palette.success.main
        : props.action === Status.Watch
            ? theme.palette.warning.main
            : props.action === Status.Act
                ? theme.palette.error.main
                : "#f8f7f6";
    var selectedBorder = (props.action === Status.Maintain
        ? theme.palette.success.dark
        : props.action === Status.Watch
            ? theme.palette.warning.dark
            : theme.palette.error.dark) + "C0";
    var ScoreCardInnerContainer = styled(Box)(function () { return ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100px",
        minWidth: "150px",
        height: "100%",
    }); });
    var ScoreCardContainer = function (_a) {
        var children = _a.children;
        return (_jsx(Box, __assign({ "aria-label": "Score ".concat(props.title, " as ").concat(props.action), onClick: props.onClick, sx: {
                display: "table-cell",
                fontFamily: theme.typography.fontFamily + " Heavy",
                fontWeight: "800",
                color: "#4f5666",
                padding: "15px",
                borderRadius: "24px",
                border: "4px solid ".concat(props.selected ? selectedBorder : theme.palette.primary.dark + "33"),
                backgroundColor: cardColor,
                maxWidth: "200px",
                height: "100%",
                cursor: "".concat(props.onClick ? "pointer" : "default"),
            } }, { children: _jsx(ScoreCardInnerContainer, { children: children }) })));
    };
    var ScoreCardTitle = styled(Box)(function () { return ({
        fontSize: "13px",
        lineHeight: "20px",
    }); });
    var ScoreCardScore = styled(Box)(function () { return ({
        fontSize: "20px",
        lineHeight: "32px",
    }); });
    var ScoreAndQuestion = styled(Box)(function () { return ({
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    }); });
    return (_jsxs(ScoreCardContainer, { children: [_jsx(ScoreCardTitle, { children: props.title }), _jsxs(ScoreAndQuestion, { children: [_jsx(ScoreCardScore, { children: props.score }), _jsx(CardTooltip, { tooltip: props.tooltip })] })] }));
};
export default ScoreCard;
