var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { createElement, useContext, useEffect, useRef, useState, } from "react";
import { Select, Stack, Form, TextArea, TextInputWidth, ConfigProvider, Icon, IconName, InfoBar, Panel, PanelSize, Card, CardSize, Avatar, ButtonShape, ButtonVariant, Dropdown as DropdownOctuple, ButtonWidth, ButtonSize, ButtonIconAlign, ButtonTextAlign, InfoBarType, TextInput, Stepper, StepperSize, StepperVariant, Accordion, AccordionSize, } from "@eightfold.ai/octuple";
import Dialog from "../Dialog";
import styles from "./NineBox.module.scss";
// Please don't repeat this method of importing icons
// this is a workaround for Octuple not having the
// suggested icons.
import AppsIcon from "@mui/icons-material/Apps";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { updateNineBoxLeadersQuery, getBoxesWithLabels, useGetFilteredNineBox, newVersionNineBoxQuery, useGetAvailableNineBoxes, generateFileName, getLeaderScoresParamString, addNineBoxAuditLogQuery, useGetNineBox, deleteNineBoxQuery, revertVersionNineBoxQuery, } from "../../store/api/core";
import SpinnerAndErrorer from "../SpinnerAndErrorer";
import { BoxLeaderScoreType, NineBoxAuditLogUpdateType, } from "../../store/api/types/core";
import { formattedDate, formattedTime, apiBase } from "../../store/api/core";
import Button, { IconButton } from "../Button/Button";
import { ButtonVariant as IntelligenceButtonVariant } from "../Button/Button";
import { DisappearingInfoBar } from "../InfoBar/InfoBar";
import FormRequired from "../FormRequired";
import { createPortal } from "react-dom";
import { useLocation, useParams } from "react-router-dom";
import { FilterContext, getFilters, updateFilters, } from "../../providers/FilterProvider";
import { GlobalStateContext, } from "../../providers/GlobalStateProvider";
import { useNavigate } from "../../hooks/navigate";
import { ColumnDescribedTable, ValidSortDirections, } from "../Table/Table";
import Dropdown from "../Dropdown";
import { useEightfold } from "../../hooks/eightFold";
import { AvailableFilters, DefaultFilterSet, Filters, } from "../Filters/Filters";
import Tooltip, { TooltipPlacement } from "../Tooltip/Tooltip";
import { getRoute } from "../../routes/getAllRoutes";
import RevertIcon from "../RevertIcon";
import useWindowDimensions, { isOverflowed, } from "../../hooks/windowDimensions";
import DownloadButton from "../DownloadButton/DownloadButton";
var ArrangeBringForwardIcon = function () { return (_jsxs("svg", __assign({ viewBox: "0 0 24 24", style: {
        height: "20px",
        width: "20px",
    } }, { children: [_jsx("title", { children: "arrange-bring-forward" }), _jsx("path", { d: "M2,2H16V16H2V2M22,8V22H8V18H10V20H20V10H18V8H22Z", fill: "#C15151" })] }))); };
var grey = "#F6F7F8";
var ToggleButton = function (_a) {
    var selected = _a.selected, icon = _a.icon, onClick = _a.onClick;
    return (_jsx("div", __assign({ style: __assign(__assign({}, (selected
            ? {
                backgroundColor: "#BCE4FF",
                border: "1px solid #2C8CC9",
            }
            : {})), { borderRadius: "24px", color: "#054D7B", padding: "8px 16px", width: "52px", height: "36px", cursor: "pointer" }), onClick: onClick }, { children: icon })));
};
var Controls = function (_a) {
    var children = _a.children;
    return (_jsxs(Stack, __assign({ style: {
            padding: "0 32px 0 80px",
            fontFamily: "Avenir",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
        }, fullWidth: true, justify: "space-between" }, { children: [_jsxs(Stack, __assign({ flexGap: "xs" }, { children: [_jsx("div", { style: {
                            border: "1px solid #1A212E",
                            borderRadius: "50px",
                            height: "20px",
                            width: "32px",
                        } }), _jsx("div", __assign({ style: {} }, { children: "Placement saved" })), _jsx(ArrangeBringForwardIcon, {}), _jsx("div", __assign({ style: {} }, { children: "Moved from recommended box" }))] })), children] })));
};
var NotifyEventName = "NotifyEventName";
var notify = function (type, content) {
    window.dispatchEvent(new CustomEvent(NotifyEventName, {
        detail: JSON.stringify({ type: type, content: content }),
    }));
};
var BannerStack = function (_a) {
    var fixed = _a.fixed;
    var _b = useState([]), banners = _b[0], setBanners = _b[1];
    var eventListener = function (event) {
        var _a = JSON.parse(event.detail), type = _a.type, content = _a.content;
        setBanners(function (previous) { return __spreadArray([
            _jsx(DisappearingInfoBar, { content: content, type: type }, Math.random())
        ], previous, true); });
    };
    useEffect(function () {
        window.addEventListener(NotifyEventName, eventListener);
        return function () {
            window.removeEventListener(NotifyEventName, eventListener);
        };
    }, []);
    return (_jsxs(Stack, __assign({ style: { padding: "0px" }, fullWidth: true, direction: "vertical", gap: "s" }, { children: [banners, fixed] })));
};
export var LeaderListContent = function (_a) {
    var setShownLeader = _a.setShownLeader, getLeaderBoxForTable = _a.getLeaderBoxForTable, id = _a.id, notify = _a.notify;
    var assigned_box = useParams().assigned_box;
    var assignedBoxIndex = String(Number(assigned_box) + 1);
    var useDataHook = function (_a) {
        var id = _a.id;
        return useGetFilteredNineBox({
            id: id,
            assigned_box: assignedBoxIndex,
        });
    };
    var controls = (_jsx("div", __assign({ className: styles.boxListContainer }, { children: _jsx(Controls, {}) })));
    var content = (_jsx(Table, { showLeader: setShownLeader, id: id, notify: notify, useDataHook: useDataHook, assignedBoxFactory: getLeaderBoxForTable }));
    return (_jsx(NineBoxContentStack, { bannerStack: null, controls: controls, content: content }));
};
export var NineBoxContentStack = function (_a) {
    var content = _a.content, controls = _a.controls, topClass = _a.topClass, fixedBanner = _a.fixedBanner;
    return (_jsxs("div", { children: [_jsx("hr", { className: "".concat(styles.horizontalRule, " ").concat(topClass || "") }), _jsxs("div", __assign({ className: styles.allBannerStack }, { children: [_jsx("div", __assign({ className: styles.disappearingBannerStack }, { children: _jsx(BannerStack, {}) })), fixedBanner] })), controls, content] }));
};
var TableNineBoxToggleContent = function (_a) {
    var _b, _c;
    var setShownLeader = _a.setShownLeader, getLeaderBoxForTable = _a.getLeaderBoxForTable, extraPillProps = _a.extraPillProps, id = _a.id, notify = _a.notify, getSeeAllLeadersUrl = _a.getSeeAllLeadersUrl;
    var _d = useState("grid"), selected = _d[0], setSelected = _d[1];
    var selectables = {
        grid: (_jsx(Grid, { extraPillProps: extraPillProps, showLeader: setShownLeader, getSeeAllLeadersUrl: getSeeAllLeadersUrl, id: id, notify: notify })),
        table: (_jsx(Table, { showLeader: setShownLeader, id: id, notify: notify, assignedBoxFactory: getLeaderBoxForTable })),
    };
    if (((_c = (_b = getFilters()) === null || _b === void 0 ? void 0 : _b.assigned_box) === null || _c === void 0 ? void 0 : _c.length) > 0) {
        // Clear the assigned box filter if we're not in the leader detailed list view
        updateFilters({
            assigned_box: [],
        });
    }
    var controls = (_jsx(Controls, { children: _jsxs(Stack, __assign({ style: {
                backgroundColor: grey,
                width: "112px",
                height: "44px",
                padding: "4px",
                borderRadius: "246px",
            } }, { children: [_jsx(ToggleButton, { selected: selected == "table", icon: _jsx(TableRowsIcon, { sx: { height: "20px", width: "20px" } }), onClick: function () {
                        setSelected("table");
                    } }), _jsx(ToggleButton, { selected: selected == "grid", icon: _jsx(AppsIcon, { sx: { height: "20px", width: "20px" } }), onClick: function () {
                        setSelected("grid");
                    } })] })) }));
    return (_jsx(NineBoxContentStack, { topClass: selected == "grid" ? styles.gridHorizontalRuleShell : undefined, bannerStack: null, content: selectables[selected], fixedBanner: selected == "grid" ? _jsx(FixedBanner, { id: id }) : undefined, controls: controls }));
};
export var mergedNames = function (index, name) {
    return "".concat(styles[name], " ").concat(styles[name + index.toString()]);
};
var YTitle = function (_a) {
    var text = _a.text;
    return (_jsx("div", __assign({ className: styles.yTitleText }, { children: text })));
};
export var EditIconedLabel = function (_a) {
    var revertCallback = _a.revertCallback, editedText = _a.editedText, originalText = _a.originalText;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { children: [editedText || originalText, " "] }), editedText && (_jsx("div", __assign({ onClick: revertCallback, className: styles.labelRevertIcon }, { children: _jsx(RevertIcon, {}) })))] }));
};
var YLabel = function (_a) {
    var index = _a.index, description = _a.description, children = _a.children;
    return (_jsx("div", __assign({ className: mergedNames(index, "yLabel") }, { children: _jsx(Tooltip, __assign({ content: description, placement: TooltipPlacement.Right, parentClassName: styles.verticalLabelTooltip }, { children: _jsx("div", __assign({ className: styles.yLabelText }, { children: typeof children == "string" ? _jsx("div", { children: children }) : children })) })) })));
};
var XLabel = function (_a) {
    var index = _a.index, description = _a.description, children = _a.children;
    return (_jsx("div", __assign({ className: mergedNames(index, "xLabel") }, { children: _jsx(Tooltip, __assign({ content: description, placement: TooltipPlacement.Top }, { children: _jsx("div", __assign({ className: styles.xLabelText }, { children: typeof children == "string" ? _jsx("div", { children: children }) : children })) })) })));
};
var XTitle = function (_a) {
    var text = _a.text;
    return _jsx("div", { children: text });
};
//Placeholder for moving leader
var movedLeader = {
    leaderProfileId: "",
    recommendedBox: 0,
    assignedBox: 0,
    firstName: "",
    lastName: "",
};
var ShowMovementDialog = function (_a) {
    var onClose = _a.onClose, onDone = _a.onDone, notify = _a.notify;
    return createPortal(_jsx(ConfigProvider, { children: _jsx(LeaderMovedConfirmationDialog, { notify: notify, onClose: onClose, onDone: onDone, firstName: movedLeader.firstName, lastName: movedLeader.lastName }) }), document.body);
};
var LeaderMovedConfirmationDialog = function (props) {
    var form = Form.useForm()[0];
    var id = useParams().id;
    var invalidates = [
        [{ queryKey: "{ninebox}/nine-box/".concat(id) }, {}],
        [{ queryKey: "{ninebox}/nine-box?clientId={ClientId}" }, {}],
    ];
    var updateNineBoxLeaders = updateNineBoxLeadersQuery({
        substitutions: { id: id },
        invalidates: invalidates,
    });
    var addNineBoxAuditLog = addNineBoxAuditLogQuery({
        substitutions: { id: id },
        invalidates: [{ queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] }],
    });
    var onDoneClick = function () {
        form.submit();
    };
    var onFormFinish = function (formValues) {
        var movedNotes = formValues.notes == undefined ? "" : formValues.notes;
        var boxLeaders = [
            {
                leaderProfileId: movedLeader.leaderProfileId,
                assignedBox: movedLeader.recommendedBox + 1,
                isSaved: true,
                isMoved: true,
                movedReason: formValues.reason,
                movedNotes: movedNotes,
            },
        ];
        updateNineBoxLeaders.mutate({ boxLeaders: boxLeaders }, {
            onSuccess: function () {
                addNineBoxAuditLog.mutate({
                    updateType: NineBoxAuditLogUpdateType.MoveLeader,
                    updateCount: 1,
                });
                props.notify(InfoBarType.positive, "".concat(props.firstName, " ").concat(props.lastName, "'s new placement was saved"));
                props.onDone && props.onDone();
            },
        });
    };
    var onReset = function () {
        form.resetFields();
    };
    var onSelectChange = function (selectOption) {
        if (!selectOption) {
            return onReset();
        }
        form.setFieldListValues({
            selectValue: selectOption[0],
            reason: selectOption[0],
        });
    };
    var SelectReasonBoxForm = function () {
        var reasonOptions = [
            {
                text: "Recent career transition (e.g. job change)",
                value: "Recent career transition (e.g. job change)",
            },
            {
                text: "Business or organizational changes (e.g. restructuring)",
                value: "Business or organizational changes (e.g. restructuring)",
            },
            {
                text: "Strategic alignment (or misalignment)",
                value: "Strategic alignment (or misalignment)",
            },
            {
                text: "Impact on morale and culture",
                value: "Impact on morale and culture",
            },
            {
                text: "Limited growth aspiration",
                value: "Limited growth aspiration",
            },
            {
                text: "Performance trending (up or down)",
                value: "Performance trending (up or down)",
            },
            {
                text: "Leadership competencies or gaps",
                value: "Leadership competencies or gaps",
            },
            {
                text: "Development, growth and career progression",
                value: "Development, growth and career progression",
            },
            {
                text: "Other (please specify)",
                value: "Other (please specify)",
            },
        ];
        return (_jsxs(_Fragment, { children: [_jsx(FormRequired, {}), _jsxs(Form, __assign({ form: form, layout: "vertical", autoComplete: "off", onFinish: onFormFinish }, { children: [_jsx(Form.Item, __assign({ label: "Reason for move", name: "reason", rules: [{ required: true, message: "Reason is required" }] }, { children: _jsx(Select, { textInputProps: {
                                    inputWidth: TextInputWidth.fill,
                                }, dropdownProps: { dropdownStyle: { maxHeight: "280px" } }, filterable: true, options: reasonOptions, onOptionsChange: onSelectChange, placeholder: "Select reason" }) })), _jsx(Form.Item, __assign({ noStyle: true, shouldUpdate: function (prevValues, currentValues) {
                                return prevValues.selectValue !== currentValues.selectValue;
                            } }, { children: function (_a) {
                                var getFieldValue = _a.getFieldValue;
                                return (_jsx(Form.Item, __assign({ label: "Add notes", name: "notes", rules: [
                                        {
                                            required: getFieldValue("selectValue") === "Other (please specify)"
                                                ? true
                                                : false,
                                            message: "Notes are required",
                                        },
                                        {
                                            type: "string",
                                            max: 100,
                                            message: "Notes cannot be more than ${max} characters",
                                        },
                                    ], style: { marginBottom: "0" } }, { children: _jsx(TextArea, { inputWidth: TextInputWidth.fill }) })));
                            } }))] }))] }));
    };
    return (_jsx(Dialog, { header: "Confirm why you're moving ".concat(props.firstName, " ").concat(props.lastName), body: _jsx(SelectReasonBoxForm, {}), visible: true, buttons: {
            okButtonProps: {
                text: "Confirm and save",
                onClick: onDoneClick,
                loading: updateNineBoxLeaders.isPending,
            },
            cancelButtonProps: {
                text: "Cancel the move",
            },
        }, onClose: props.onClose }));
};
var pillDragStart = function (profileId, abox, firstName, lastName) {
    movedLeader = {
        leaderProfileId: profileId,
        assignedBox: abox,
        firstName: firstName,
        lastName: lastName,
    };
};
var getNameInitials = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName;
    return [firstName === null || firstName === void 0 ? void 0 : firstName[0], lastName === null || lastName === void 0 ? void 0 : lastName[0]]
        .map(function (character) { return character || ""; })
        .join("")
        .toUpperCase();
};
var LeaderPill = function (_a) {
    var id = _a.id, firstName = _a.firstName, lastName = _a.lastName, leaderProfileId = _a.leaderProfileId, assignedBox = _a.assignedBox, isMoving = _a.isMoving, setIsMoving = _a.setIsMoving, setDragSourceBoxIndex = _a.setDragSourceBoxIndex, isMoved = _a.isMoved, isSaved = _a.isSaved, onClick = _a.onClick, extraProps = _a.extraProps;
    var _b = useState(false), isDragging = _b[0], setIsDragging = _b[1];
    var initials = getNameInitials({ firstName: firstName, lastName: lastName });
    var handleOnDragStart = function () {
        pillDragStart(leaderProfileId, assignedBox, firstName, lastName);
        setDragSourceBoxIndex(assignedBox);
        setIsDragging(true);
        setIsMoving(true);
    };
    var handleOnDragEnd = function () {
        setIsMoving(false);
        setIsDragging(false);
    };
    return (_jsxs("div", __assign({ id: id, className: "".concat(isMoved ? styles.leaderTileMoved : styles.leaderTile, " ").concat(isMoving ? styles.draggingPill : "", " ").concat(isDragging ? styles.draggingPill : "", "\n       ").concat(isSaved ? styles.leaderTileMoved : ""), onDragStart: handleOnDragStart, onDragEnd: handleOnDragEnd, onClick: onClick }, (extraProps ? extraProps : { draggable: true }), { children: [_jsx("div", __assign({ className: styles.leaderInitials }, { children: initials })), _jsx("div", __assign({ className: styles.leaderName }, { children: "".concat(firstName, " ").concat(lastName) })), isMoved && _jsx(ArrangeBringForwardIcon, {})] })));
};
var SpilloverBottom = function (_a) {
    var boxNumber = _a.boxNumber, pillCount = _a.pillCount, onClick = _a.onClick;
    var _b = useState(0), spillover = _b[0], setSpillover = _b[1];
    useWindowDimensions();
    setTimeout(function () {
        var spilloverCount = 0;
        var base = document.querySelector("#leaderNames".concat(boxNumber));
        for (var i = 0; i < pillCount; i++) {
            var pill = document.querySelector("#leaderPill".concat(i, "-").concat(boxNumber));
            if (base && pill && isOverflowed(pill, base)) {
                spilloverCount++;
            }
        }
        setSpillover(spilloverCount);
    }, 0);
    return spillover ? (_jsxs("div", __assign({ className: styles.leaderOverflow }, { children: [_jsx("hr", { className: styles.leaderDivider }), _jsxs("div", __assign({ className: styles.leaderOverflowDescription }, { children: [_jsxs("div", __assign({ className: styles.leaderSpilloverCount }, { children: ["+", spillover] })), _jsx("div", __assign({ className: styles.leaderClickSeeOverflow, onClick: onClick }, { children: "See all leaders >" }))] }))] }))) : (_jsx(_Fragment, {}));
};
var Box = function (_a) {
    var index = _a.index, leaders = _a.leaders, notify = _a.notify, _b = _a.description, name = _b.name, boxDescription = _b.boxDescription, isMoving = _a.isMoving, setIsMoving = _a.setIsMoving, dragSourceBoxIndex = _a.dragSourceBoxIndex, setDragSourceBoxIndex = _a.setDragSourceBoxIndex, showLeader = _a.showLeader, extraPillProps = _a.extraPillProps, getSeeAllLeadersUrl = _a.getSeeAllLeadersUrl;
    var _c = useState(false), isMoved = _c[0], setIsMoved = _c[1];
    var _d = useState(false), isDragOver = _d[0], setIsDragOver = _d[1];
    var assignedLeaders = leaders.filter(function (_a) {
        var assignedBox = _a.assignedBox;
        return assignedBox == index;
    });
    var navigate = useNavigate();
    var id = useParams().id;
    var getStartingPills = function () {
        return assignedLeaders.map(function (boxLeader, i) { return (_jsx(LeaderPill, { id: "leaderPill".concat(i, "-").concat(index), firstName: boxLeader.firstName, lastName: boxLeader.lastName, leaderProfileId: boxLeader.leaderProfileId, assignedBox: boxLeader.assignedBox, isMoving: isMoving, setIsMoving: setIsMoving, setDragSourceBoxIndex: setDragSourceBoxIndex, isMoved: boxLeader.isMoved, isSaved: boxLeader.isSaved, onClick: function () {
                showLeader(boxLeader);
            }, extraProps: extraPillProps }, "leaderPill".concat(i, "-").concat(index))); });
    };
    var pills = getStartingPills();
    var leaderCount = assignedLeaders.length;
    var spilloverCount = assignedLeaders.length - pills.length;
    var onPillDrop = function (e, index) {
        e.preventDefault();
        if (movedLeader.assignedBox != index) {
            movedLeader.recommendedBox = index;
            setIsMoved(true);
        }
        setIsDragOver(false);
    };
    var onPillDragEnter = function (e) {
        e.preventDefault();
        setIsDragOver(true);
    };
    var onPillDragLeave = function (e) {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsDragOver(false);
        }
    };
    var getDefaultSeeAllLeadersUrl = function (id, box) {
        return getRoute("TalentReviewNineBoxLeaderView")
            .replace(":assigned_box", box)
            .replace(":id", id);
    };
    var handleSeeAllLeaders = function (assignedBox) {
        if (!id) {
            return;
        }
        navigate((getSeeAllLeadersUrl || getDefaultSeeAllLeadersUrl)(id, assignedBox));
    };
    var pillBox = (_jsx("div", __assign({ id: "leaderNames".concat(index), className: styles.leaderBox }, { children: _jsx(Stack, __assign({ direction: "horizontal", wrap: "wrap" }, { children: pills })) })));
    return (_jsxs("div", __assign({ className: "".concat(mergedNames(index, "box"), " ").concat(isDragOver && dragSourceBoxIndex !== index ? styles.dragOverBox : ""), onDrop: function (e) { return onPillDrop(e, index); }, onDragOver: function (e) {
            e.preventDefault();
            setIsDragOver(true);
        }, onDragEnter: function (e) { return onPillDragEnter(e); }, onDragLeave: function (e) { return onPillDragLeave(e); } }, { children: [_jsx("div", __assign({ style: { cursor: "pointer" }, onClick: function () { return handleSeeAllLeaders(index); } }, { children: _jsx(Tooltip, __assign({ content: boxDescription, placement: TooltipPlacement.Right }, { children: _jsx("div", __assign({ className: styles.boxName }, { children: "".concat((name || "").toUpperCase(), " (").concat(leaderCount, ")") })) })) })), pillBox, _jsx(SpilloverBottom, { boxNumber: index, pillCount: pills.length, onClick: function () { return handleSeeAllLeaders(index); } }, Math.random()), false && spilloverCount > 0 && (_jsxs("div", __assign({ className: styles.leaderOverflow }, { children: [_jsx("hr", { className: styles.leaderDivider }), _jsxs("div", __assign({ className: styles.leaderOverflowDescription }, { children: [_jsxs("div", __assign({ className: styles.leaderSpilloverCount }, { children: ["+", spilloverCount] })), _jsx("div", __assign({ className: styles.leaderClickSeeOverflow, onClick: function () { return handleSeeAllLeaders(index); } }, { children: "See all leaders >" }))] }))] }))), isMoved && (_jsx(ShowMovementDialog, { onClose: function () { return setIsMoved(false); }, onDone: function () { return setIsMoved(false); }, notify: notify }))] })));
};
export var SkeletonGrid = function (_a) {
    var boxes = _a.boxes, xAxis = _a.xAxis, yAxis = _a.yAxis;
    var performanceToolTipText = "To what extent does this leader meet performance standards, objectives, and drive business results?";
    var trajectoryToolTipText = "What is the leader's projected career and development path into roles of increasing scope, complexity and impact?";
    var xAxisToolTipContent = (xAxis === null || xAxis === void 0 ? void 0 : xAxis.dataSources) == "Performance"
        ? performanceToolTipText
        : trajectoryToolTipText;
    var yAxisToolTipContent = (yAxis === null || yAxis === void 0 ? void 0 : yAxis.dataSources) == "Performance"
        ? performanceToolTipText
        : trajectoryToolTipText;
    return (_jsxs("div", __assign({ className: styles.wholeGrid, "data-testid": "ninebox-grid" }, { children: [_jsx("div", __assign({ className: styles.yTitle }, { children: _jsx(Tooltip, __assign({ content: yAxisToolTipContent, placement: TooltipPlacement.Right, className: styles.verticalTitleTooltip }, { children: _jsx(YTitle, { text: yAxis.axisLabel }) })) })), _jsx(YLabel, __assign({ index: 0, description: yAxis.lowDescription }, { children: yAxis.lowLabel })), _jsx(YLabel, __assign({ index: 1, description: yAxis.middleDescription }, { children: yAxis.middleLabel })), _jsx(YLabel, __assign({ index: 2, description: yAxis.highDescription }, { children: yAxis.highLabel })), _jsx("div", __assign({ className: styles.xTitle }, { children: _jsx(Tooltip, __assign({ placement: TooltipPlacement.Top, content: xAxisToolTipContent }, { children: _jsx(XTitle, { text: xAxis.axisLabel }) })) })), _jsx(XLabel, __assign({ index: 0, description: xAxis.lowDescription }, { children: xAxis.lowLabel })), _jsx(XLabel, __assign({ index: 1, description: xAxis.middleDescription }, { children: xAxis.middleLabel })), _jsx(XLabel, __assign({ index: 2, description: xAxis.highDescription }, { children: xAxis.highLabel })), boxes] })));
};
var Grid = function (_a) {
    var _b;
    var id = _a.id, notify = _a.notify, showLeader = _a.showLeader, extraPillProps = _a.extraPillProps, getSeeAllLeadersUrl = _a.getSeeAllLeadersUrl;
    var _c = useState(false), isMoving = _c[0], setIsMoving = _c[1];
    var _d = useState(null), dragSourceBoxIndex = _d[0], setDragSourceBoxIndex = _d[1];
    var nineBox = useGetFilteredNineBox({ id: id });
    if (!(nineBox === null || nineBox === void 0 ? void 0 : nineBox.data)) {
        return _jsx("div", {});
    }
    var _e = ((_b = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _b === void 0 ? void 0 : _b.data) || {}, xAxis = _e.xAxis, yAxis = _e.yAxis, boxes = _e.boxes, leaders = _e.leaders;
    return (_jsx(SpinnerAndErrorer, __assign({ conditions: [nineBox] }, { children: _jsx(SkeletonGrid, { xAxis: xAxis, yAxis: yAxis, boxes: boxes.map(function (description, index) {
                return (_jsx(Box, { index: index, leaders: leaders, description: description, isMoving: isMoving, getSeeAllLeadersUrl: getSeeAllLeadersUrl, setIsMoving: setIsMoving, showLeader: showLeader, dragSourceBoxIndex: dragSourceBoxIndex, setDragSourceBoxIndex: setDragSourceBoxIndex, extraPillProps: extraPillProps, notify: notify }, "box".concat(index)));
            }) }) })));
};
var Table = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var showLeader = _a.showLeader, id = _a.id, notify = _a.notify, useDataHook = _a.useDataHook, assignedBoxFactory = _a.assignedBoxFactory;
    var nineBox = (useDataHook || useGetFilteredNineBox)({ id: id });
    var shownLeader = useRef();
    if (shownLeader.current &&
        !((_d = (_c = (_b = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.leaders) === null || _d === void 0 ? void 0 : _d.map(function (_a) {
            var leaderProfileId = _a.leaderProfileId;
            return leaderProfileId;
        }).includes((_e = shownLeader.current) === null || _e === void 0 ? void 0 : _e.leaderProfileId))) {
        showLeader(null);
    }
    var NameAndRole = function (_a) {
        var row = _a.row;
        var firstName = row.firstName, lastName = row.lastName, roleName = row.roleName, isMoved = row.isMoved, isSaved = row.isSaved, boxLeader = row.boxLeader;
        var initialLetters = firstName[0] + lastName[0];
        return (_jsxs("div", __assign({ className: styles.nameRoleWrapper, onClick: function () {
                shownLeader.current = boxLeader;
                showLeader(boxLeader);
            } }, { children: [_jsx("div", __assign({ className: styles.nameBubble }, { children: initialLetters.toUpperCase() })), _jsxs("div", { children: [_jsx("div", __assign({ className: styles.nameAndRoleContainer }, { children: _jsxs("span", __assign({ className: styles.nameAndRole, style: isSaved
                                    ? { border: "1px solid #69717F", borderRadius: "50px" }
                                    : {} }, { children: [firstName, " ", lastName, _jsx("span", __assign({ style: { marginLeft: "8px" } }, { children: isMoved ? _jsx(ArrangeBringForwardIcon, {}) : "" }))] })) })), _jsx("div", __assign({ className: styles.roles }, { children: roleName }))] })] })));
    };
    var TimeInRole = function (_a) {
        var row = _a.row;
        return (_jsx("div", { children: _jsxs("div", __assign({ className: styles.timeAndRole }, { children: [row.timeInRole, " years"] })) }));
    };
    var NineBoxRecommended = function (_a) {
        var row = _a.row;
        var jsonString = JSON.stringify(row);
        var parseString = JSON.parse(jsonString);
        return (_jsxs("div", __assign({ className: styles.columnTitles }, { children: [_jsx("div", __assign({ className: styles.tableColumnTitle }, { children: parseString.name })), _jsxs("div", __assign({ className: styles.columnSubTitle }, { children: [_jsx("div", { children: "".concat(parseString.xDataSources == "Performance"
                                ? "".concat(parseString.xAxisLabel, ": ").concat(parseString.xAxisPlace)
                                : "".concat(parseString.yAxisLabel, ": ").concat(parseString.yAxisPlace)) }), _jsx("div", { children: "".concat(parseString.yDataSources == "Performance"
                                ? "".concat(parseString.xAxisLabel, ": ").concat(parseString.xAxisPlace)
                                : "".concat(parseString.yAxisLabel, ": ").concat(parseString.yAxisPlace)) })] }))] })));
    };
    var Performance = function (_a) {
        var row = _a.row;
        var performanceLabel = "".concat(row.xDataSources == "Performance"
            ? row.xPerformanceLabel
            : row.yPerformanceLabel);
        return (_jsx("div", { children: _jsxs("div", __assign({ className: styles.columnTitles }, { children: [_jsxs("div", __assign({ className: styles.tableColumnTitle }, { children: [performanceLabel, row.hasPerformanceRatings && (_jsxs("span", __assign({ style: { paddingLeft: "3px" } }, { children: ["(", row.performanceScore, "/", row.performanceMax, ")"] })))] })), _jsx("div", __assign({ className: styles.columnSubTitle }, { children: row.hasPerformanceRatings ? (row.performanceRatings.map(function (rating) { return (_jsxs("div", { children: [rating.endDate.getFullYear(), ": ", rating.ratingDescription] })); })) : (_jsx("div", { children: "--" })) }))] })) }));
    };
    var LeadershipTrajectory = function (_a) {
        var row = _a.row;
        var performanceLabel = "".concat(row.yDataSources == "Performance"
            ? row.xTrajectoryLabel
            : row.yTrajectoryLabel);
        return (_jsx("div", { children: _jsxs("div", __assign({ className: styles.columnTitles }, { children: [_jsx("div", __assign({ className: styles.tableColumnTitle }, { children: performanceLabel })), _jsxs("div", __assign({ className: styles.columnSubTitle }, { children: [_jsxs("div", { children: ["Capability: ", row.capabilitiesCategory, " (", row.capabilitiesScore, "/", row.trajectoryMax, ")"] }), _jsxs("div", { children: ["Potential: ", row.potentialCategory, " (", row.potentialScore, "/", row.trajectoryMax, ")"] })] }))] })) }));
    };
    var NineBoxAssigned = function (_a) {
        var row = _a.row;
        var jsonString = JSON.stringify(row);
        var parseString = JSON.parse(jsonString);
        var _b = useState(false), ddvisible = _b[0], setDdvisible = _b[1];
        var _c = useState(IconName.mdiChevronDown), arrowIcon = _c[0], setArrowIcon = _c[1];
        var _d = useState([]), itemList = _d[0], setItemList = _d[1];
        var _e = useState(false), isSelected = _e[0], setIsSelected = _e[1];
        var id = useParams().id;
        var _f = useGetFilteredNineBox({ id: id }), isFetched = _f.isFetched, data = _f.data;
        var toggleDropdown = function () {
            if (ddvisible) {
                setDdvisible(false);
                setArrowIcon(IconName.mdiChevronDown);
            }
            else {
                setDdvisible(true);
                setArrowIcon(IconName.mdiChevronUp);
                getBoxDetails();
            }
        };
        var selectItem = function (key) {
            movedLeader.leaderProfileId = row.leaderProfileId;
            movedLeader.firstName = row.firstName;
            movedLeader.lastName = row.lastName;
            movedLeader.recommendedBox = key;
            setIsSelected(true);
            setDdvisible(false);
            setArrowIcon(IconName.mdiChevronDown);
        };
        var dropdownItem = function (item) {
            return (_jsxs("div", __assign({ className: item.name == row.name
                    ? styles.tableAssignedCellDropdownItemSelected
                    : styles.tableAssignedCellDropdownItem, onClick: function () { return selectItem(item.index); } }, { children: [_jsx("div", { children: _jsx("strong", { children: item.name }) }), _jsx("div", { children: item.xDataSources == "Performance"
                            ? "".concat(item.xlabel, ": ").concat(item.xplace)
                            : "".concat(item.ylabel, ": ").concat(item.yplace) }), _jsx("div", { children: item.yDataSources == "Performance"
                            ? "".concat(item.xlabel, ": ").concat(item.xplace)
                            : "".concat(item.ylabel, ": ").concat(item.yplace) })] }), item.index));
        };
        var getBoxDetails = function () {
            if (isFetched) {
                if ((data === null || data === void 0 ? void 0 : data.data) != undefined) {
                    var boxWithLabels = getBoxesWithLabels(data === null || data === void 0 ? void 0 : data.data);
                    var items = boxWithLabels.map(function (item) { return dropdownItem(item); });
                    setItemList(items);
                }
            }
        };
        var outsideClick = function (event) {
            var elm = document.getElementById("tabledropdown-button-".concat(row.leaderProfileId));
            if (!(elm === null || elm === void 0 ? void 0 : elm.contains(event.target))) {
                setDdvisible(false);
                setArrowIcon(IconName.mdiChevronDown);
            }
        };
        return (_jsxs("div", __assign({ className: styles.tableAssignedCell }, { children: [_jsxs("div", __assign({ className: styles.tableAssignedCellContent }, { children: [_jsx("div", __assign({ className: styles.tableColumnTitle }, { children: parseString.name })), _jsxs("div", __assign({ className: styles.columnSubTitle }, { children: [_jsx("div", { children: "".concat(parseString.xDataSources == "Performance"
                                        ? "".concat(parseString.xAxisLabel, ": ").concat(parseString.xAxisPlace)
                                        : "".concat(parseString.yAxisLabel, ": ").concat(parseString.yAxisPlace)) }), _jsx("div", { children: "".concat(parseString.yDataSources == "Performance"
                                        ? "".concat(parseString.xAxisLabel, ": ").concat(parseString.xAxisPlace)
                                        : "".concat(parseString.yAxisLabel, ": ").concat(parseString.yAxisPlace)) })] }))] })), _jsx(DropdownOctuple, __assign({ closeOnDropdownClick: true, closeOnOutsideClick: true, dropdownClassNames: styles.tableAssignedCellDropdown, dropdownStyle: {}, initialFocus: false, onClickOutside: function (event) { return outsideClick(event); }, offset: 0, overlay: itemList, placement: "bottom-start", positionStrategy: "absolute", style: { width: "85%", height: "0px" }, visible: ddvisible, trigger: "click" }, { children: _jsx("div", { style: { display: "flex" } }) })), _jsx("div", __assign({ className: styles.tableAssignedCellCarrot }, { children: _jsx("div", __assign({ style: { fontSize: "30px;", cursor: "pointer" }, onClick: function () { return toggleDropdown(); }, id: "tabledropdown-button-".concat(row.leaderProfileId) }, { children: _jsx(Icon, { className: styles.tableAssignedCellCarrotIcon, "data-test-id": "leader-dropdown-carrot", id: "myIconId", path: arrowIcon, role: "presentation", rotate: 0 }) })) })), isSelected &&
                    createPortal(_jsx(ConfigProvider, { children: _jsx(LeaderMovedConfirmationDialog, { notify: notify, visible: isSelected, onClose: function () { return setIsSelected(false); }, firstName: movedLeader.firstName, lastName: movedLeader.lastName }) }), document.body)] })));
    };
    var ReasonForMovement = function (_a) {
        var row = _a.row;
        var movedReason = row.movedReason, isMoved = row.isMoved;
        var movedReasonNote = isMoved ? movedReason : "--";
        var _b = useState(false), showDetails = _b[0], setShowDetails = _b[1];
        var Details = function () {
            return (_jsxs("div", { children: [_jsx("div", __assign({ className: styles.tableReasonForMovementDetailsTitle }, { children: movedReasonNote })), _jsx("div", __assign({ className: styles.tableReasonForMovementDetailsBody }, { children: row.movedNotes }))] }));
        };
        var ViewDetailsDialog = function (props) {
            return (_jsx(Dialog, { header: "Why ".concat(row.firstName, " ").concat(row.lastName, " was moved"), body: _jsx(Details, {}), visible: props.visible, onClose: props.onClose, buttons: { okButtonProps: { text: "Close", onClick: props.onClose } }, "data-testid": "table-view-details-dialog" }));
        };
        return (_jsxs("div", __assign({ className: styles.columnTitles, style: { width: "180px" } }, { children: [_jsx("div", __assign({ className: "".concat(styles.columnSubTitle, " ").concat(styles.movedReason) }, { children: movedReasonNote })), isMoved && (_jsx("div", __assign({ onClick: function () {
                        setShowDetails(true);
                    }, className: styles.tableReasonForMovementCellButton }, { children: "View details" }))), showDetails &&
                    createPortal(_jsx(ViewDetailsDialog, { visible: showDetails, onClose: function () { return setShowDetails(false); } }), document.body)] })));
    };
    var columnDescriptions = [
        {
            header: "Name & Role",
            defaultSortOrderKey: "Name & Role",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(NameAndRole, { row: data });
            },
            valueTransformer: function (row) {
                var firstName = row.firstName, lastName = row.lastName, isMoved = row.isMoved, isSaved = row.isSaved, efId = row.efId, roleName = row.roleName;
                var sortFirstName = firstName === null || firstName === void 0 ? void 0 : firstName.toUpperCase();
                var sortLastName = lastName === null || lastName === void 0 ? void 0 : lastName.toUpperCase();
                var sortName = "".concat(sortFirstName, " ").concat(sortLastName);
                return {
                    sort: sortName,
                    data: {
                        boxLeader: row,
                        firstName: firstName,
                        lastName: lastName,
                        isMoved: isMoved,
                        isSaved: isSaved,
                        roleName: roleName,
                        efId: efId,
                    },
                };
            },
        },
        {
            header: "Time in Role",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(TimeInRole, { row: data });
            },
            valueTransformer: function (row) {
                return {
                    sort: row.timeInRole,
                    data: {
                        timeInRole: row.timeInRoleYears,
                    },
                };
            },
        },
        {
            header: "9 Box Category (Recommended)",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(NineBoxRecommended, { row: data });
            },
            valueTransformer: function (row) {
                var _a = row.getRecommendedLabels(), xName = _a.xName, yName = _a.yName, xPlace = _a.xPlace, yPlace = _a.yPlace, name = _a.name, xDataSources = _a.xDataSources, yDataSources = _a.yDataSources;
                return {
                    sort: name,
                    data: {
                        name: name,
                        xAxisPlace: xPlace,
                        yAxisPlace: yPlace,
                        xAxisLabel: xName,
                        yAxisLabel: yName,
                        xDataSources: xDataSources,
                        yDataSources: yDataSources,
                    },
                };
            },
        },
        {
            header: "Leadership Performance (Scores)",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(Performance, { row: data });
            },
            valueTransformer: function (row) { return ({
                sort: row.getRecommendedLabels().xPlace,
                data: {
                    xPerformanceLabel: row.getRecommendedLabels().xPlace,
                    yPerformanceLabel: row.getRecommendedLabels().yPlace,
                    performanceRatings: row.performanceRatings,
                    xDataSources: row.getRecommendedLabels().xDataSources,
                    yDataSources: row.getRecommendedLabels().yDataSources,
                    performanceScore: row.performanceScore.toFixed(1),
                    performanceMax: row.getPerformanceMax().toFixed(1),
                    hasPerformanceRatings: row.performanceRatings.length > 0,
                },
            }); },
        },
        {
            header: "Leadership Trajectory (Scores)",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(LeadershipTrajectory, { row: data });
            },
            valueTransformer: function (row) { return ({
                sort: row.getRecommendedLabels().yPlace,
                data: {
                    xTrajectoryLabel: row.getRecommendedLabels().xPlace,
                    yTrajectoryLabel: row.getRecommendedLabels().yPlace,
                    xDataSources: row.getRecommendedLabels().xDataSources,
                    yDataSources: row.getRecommendedLabels().yDataSources,
                    trajectoryMax: row.getTrajectoryMax().toFixed(1),
                    potentialScore: row.trajectoryScores[BoxLeaderScoreType.Potential].score.toFixed(1),
                    potentialCategory: row.trajectoryScores[BoxLeaderScoreType.Potential]
                        .categoricalResult,
                    capabilitiesScore: row.trajectoryScores[BoxLeaderScoreType.Capabilities].score.toFixed(1),
                    capabilitiesCategory: row.trajectoryScores[BoxLeaderScoreType.Capabilities]
                        .categoricalResult,
                },
            }); },
        },
        {
            header: "9 Box Category (Assigned)",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return assignedBoxFactory ? (assignedBoxFactory({ row: data })) : (_jsx(NineBoxAssigned, { row: data }));
            },
            valueTransformer: function (row) {
                var _a = row.getAssignedLabels(), xName = _a.xName, yName = _a.yName, xPlace = _a.xPlace, yPlace = _a.yPlace, name = _a.name, xDataSources = _a.xDataSources, yDataSources = _a.yDataSources;
                var firstName = row.firstName, lastName = row.lastName, leaderProfileId = row.leaderProfileId, recommendedBox = row.recommendedBox;
                return {
                    sort: row.getAssignedLabels().name,
                    data: {
                        firstName: firstName,
                        lastName: lastName,
                        leaderProfileId: leaderProfileId,
                        recommendedBox: recommendedBox,
                        name: name,
                        xAxisPlace: xPlace,
                        yAxisPlace: yPlace,
                        xAxisLabel: xName,
                        yAxisLabel: yName,
                        xDataSources: xDataSources,
                        yDataSources: yDataSources,
                    },
                };
            },
        },
        {
            header: "Reason for Movement",
            defaultSortDirection: ValidSortDirections.ASC,
            formatter: function (_a) {
                var data = _a.data;
                return _jsx(ReasonForMovement, { row: data });
            },
            valueTransformer: function (row) { return ({
                sort: row.movedReason,
                data: {
                    firstName: row.firstName,
                    lastName: row.lastName,
                    rowData: row,
                    isMoved: row.isMoved,
                    movedReason: row.movedReason,
                    movedNotes: row.movedNotes,
                },
            }); },
        },
    ];
    var sortOrderKey = {
        column: "Name & Role",
        direction: ValidSortDirections.ASC,
    };
    return (_jsx(SpinnerAndErrorer, __assign({ conditions: [nineBox] }, { children: _jsx("div", __assign({ style: { padding: "0 32px" } }, { children: _jsx(ColumnDescribedTable, { defaultSortKeyOrder: [sortOrderKey], columnDescriptions: columnDescriptions, replaceEmpty: "--", data: (_g = (_f = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.leaders, showingInfo: showingInfo }) })) })));
};
var showingInfo = function (_a) {
    var count = _a.count, total = _a.total;
    return (_jsx("div", __assign({ style: { position: "relative" } }, { children: _jsx("div", __assign({ className: styles.showingInfo }, { children: "Showing ".concat(count, " of ").concat(total, " leader").concat(total == 1 ? "" : "s") })) })));
};
var BoxIdLoader = function (_a) {
    var _b, _c;
    var boxId = _a.boxId;
    var clearTalentReviewLabelEdits = useContext(GlobalStateContext).clearTalentReviewLabelEdits;
    var topVersion = (((_c = (_b = useGetAvailableNineBoxes({ boxId: boxId })) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.data) || [])[0];
    var navigate = useNavigate();
    if (!topVersion) {
        return _jsx(_Fragment, {});
    }
    return (_jsx(Button, { onClick: function () {
            clearTalentReviewLabelEdits();
            navigate(getRoute("TalentReviewNineBox").replace(":id", topVersion.id));
        }, text: "Done" }));
};
var DoneDialog = function (_a) {
    var _b, _c;
    var id = _a.id;
    var boxId = (((_c = (_b = useGetNineBox({ id: id })) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.data) || {}).boxId;
    if (!boxId) {
        return _jsx(_Fragment, {});
    }
    return _jsx(BoxIdLoader, { boxId: boxId });
};
export var TextOnlyTableLeaderBox = function (_a) {
    var row = _a.row;
    return (_jsx("div", __assign({ className: styles.columnTitles }, { children: _jsx("div", __assign({ className: styles.tableColumnTitle }, { children: row.name })) })));
};
export var TextOnlyPopupLeaderBox = function (_a) {
    var _b;
    var assignedBox = _a.assignedBox, options = _a.options;
    return _jsx(_Fragment, { children: ((_b = options[assignedBox]) === null || _b === void 0 ? void 0 : _b.label) || "" });
};
export var ViewOnlyInfoBar = function () { return (_jsxs("div", __assign({ className: styles.doneContainer }, { children: [" ", _jsx(InfoBar, { content: "View only", type: InfoBarType.warning }), " "] }))); };
export var getReadOnlyLeaderViewUrl = function (id, boxNumber) {
    return getRoute("TalentReviewNineBoxLeaderReadView")
        .replace(":id", id)
        .replace(":assigned_box", boxNumber);
};
export var ReadOnlyNineBox = function (_a) {
    var id = _a.id;
    return id ? (_jsx(SpecifiedNineBox, { extraPillProps: { draggable: false }, getSaverButtons: getReadOnlySaverButtons, getLeaderBoxForTable: TextOnlyTableLeaderBox, getLeaderBoxForPopup: TextOnlyPopupLeaderBox, content: TableNineBoxToggleContent, id: id, settingsPageNavigator: _jsx(_Fragment, {}), heading: _jsx(ViewOnlyInfoBar, {}), getSeeAllLeadersUrl: getReadOnlyLeaderViewUrl })) : (_jsx(UnspecifiedNineBox, {}));
};
export var NineBox = function (_a) {
    var id = _a.id;
    return id ? (_jsx(SpecifiedNineBox, { content: TableNineBoxToggleContent, extraPillProps: { draggable: true }, id: id })) : (_jsx(UnspecifiedNineBox, {}));
};
var UnspecifiedNineBox = function () { return _jsx("div", { children: "500 Internal Error" }); };
var SaveDialog = function (_a) {
    var _b, _c;
    var id = _a.id, notify = _a.notify;
    var _d = useState(false), haveDialog = _d[0], setHaveDialog = _d[1];
    var assigned_box = useParams().assigned_box;
    var assignedBox = assigned_box && Number(assigned_box) + 1;
    var nineBox = useGetFilteredNineBox({ id: id, assigned_box: assignedBox });
    var boxLeaders = ((_c = (_b = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.leaders) || [];
    var updateNineBoxLeaders = updateNineBoxLeadersQuery({
        substitutions: { id: id },
        invalidates: [
            { queryKey: ["{ninebox}/nine-box/".concat(id)] },
            { queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] },
        ],
    });
    var addNineBoxAuditLog = addNineBoxAuditLogQuery({
        substitutions: { id: id },
        invalidates: [{ queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] }],
    });
    var close = function () {
        setHaveDialog(false);
    };
    var save = function () {
        updateNineBoxLeaders.mutate({
            boxLeaders: boxLeaders.map(function (previous) { return ({
                isSaved: true,
                leaderProfileId: previous.leaderProfileId,
            }); }),
        }, {
            onSuccess: function () {
                addNineBoxAuditLog.mutate({
                    updateType: NineBoxAuditLogUpdateType.SaveLeaders,
                    updateCount: boxLeaders.filter(function (leader) { return !leader.isSaved; }).length,
                });
                notify(InfoBarType.positive, "All placements have been successfully saved");
                setHaveDialog(false);
            },
            onError: function () {
                notify(InfoBarType.disruptive, "Error saving box leaders");
                setHaveDialog(false);
            },
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: function () { return setHaveDialog(true); }, text: "Save" }), _jsx(Dialog, { body: "", header: "Please confirm you have reviewed all ".concat((boxLeaders === null || boxLeaders === void 0 ? void 0 : boxLeaders.length) || "", " leaders before saving"), onClose: close, buttons: {
                    cancelButtonProps: {
                        text: "Continue reviewing",
                        onClick: close,
                    },
                    okButtonProps: {
                        text: "Confirm and save",
                        onClick: save,
                        loading: updateNineBoxLeaders.isPending,
                    },
                }, visible: haveDialog })] }));
};
var VersionHeader = function (_a) {
    var text = _a.text, onClick = _a.onClick;
    return (_jsx("div", __assign({ className: styles.selectableVersionHeader, onClick: function () { return onClick && onClick(); } }, { children: text })));
};
var versionChangeReason = (_a = {},
    _a[NineBoxAuditLogUpdateType.SaveLeaders] = "saved",
    _a[NineBoxAuditLogUpdateType.MoveLeader] = "moved",
    _a[NineBoxAuditLogUpdateType.ChangeLabels] = "9 box labels updated",
    _a);
var VersionChangeDescription = function (_a) {
    var updateCount = _a.updateCount, updatedType = _a.updatedType;
    var changeReason = versionChangeReason[updatedType] || "";
    return (_jsx("div", __assign({ className: styles.selectableVersionChange }, { children: "".concat(isNaN(updateCount)
            ? ""
            : "".concat(updateCount, " leader").concat(updateCount > 1 ? "s" : ""), " ").concat(changeReason) })));
};
var dayInMilliseconds = 24 * 60 * 60 * 1000;
var VersionDateTime = function (_a) {
    var updatedTs = _a.updatedTs;
    var parsed = new Date(updatedTs);
    var now = new Date();
    if (parsed == "Invalid Date") {
        return _jsx(_Fragment, {});
    }
    var timed = new Date(updatedTs);
    timed.setMinutes(timed.getMinutes() - timed.getTimezoneOffset());
    var displayable = timed.toLocaleTimeString([], {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    }) +
        (now.getTime() - parsed.getTime() >= dayInMilliseconds
            ? " - ".concat(timed.toLocaleDateString())
            : "");
    return _jsx("div", __assign({ className: styles.selectableVersionDateTime }, { children: displayable }));
};
var VersionStep = function (_a) {
    var updateCount = _a.updateCount, updatedType = _a.updatedType, updatedTs = _a.updatedTs, updatedUserName = _a.updatedUserName;
    var _b = updatedUserName.split(/\s+/, 2), firstName = _b[0], lastName = _b[1];
    var initials = getNameInitials({ firstName: firstName, lastName: lastName }) || "";
    return (_jsxs(Stack, __assign({ direction: "vertical" }, { children: [_jsx(VersionDateTime, { updatedTs: updatedTs }), _jsxs(Stack, __assign({ direction: "horizontal", flexGap: "s" }, { children: [_jsx(Stack, __assign({ direction: "vertical", justify: "center" }, { children: _jsx("div", __assign({ className: styles.nameBubbleSmall }, { children: initials })) })), _jsxs(Stack, __assign({ direction: "vertical" }, { children: [_jsx("div", __assign({ className: styles.selectableVersionUserName }, { children: updatedUserName })), _jsx(VersionChangeDescription, { updateCount: updateCount, updatedType: updatedType })] }))] }))] })));
};
var VersionDialogBody = function (_a) {
    var updateLogs = _a.details.updateLogs, summary = _a.summary, expanded = _a.expanded;
    var steps = (updateLogs || [])
        .sort(function (a, b) {
        return new Date(b.updatedTs).getTime() - new Date(a.updatedTs).getTime();
    })
        .map(function (details, index) { return ({
        index: index + 1,
        content: _jsx(VersionStep, __assign({}, details)),
    }); });
    return (_jsx(Accordion, __assign({ expanded: expanded, size: AccordionSize.Medium, headerProps: {
            style: {
                backgroundColor: "var(--grey-color-10)",
                borderRadius: "32px",
                maxHeight: "unset",
            },
        }, bordered: false }, (steps.length ? {} : { expandIconProps: null }), { bodyProps: { style: { borderTop: "0" } }, summary: summary }, { children: steps.length > 0 ? (_jsx(Stepper, { layout: "vertical", steps: steps, readonly: true, size: StepperSize.Medium, scrollable: false, index: steps.length, variant: StepperVariant.Timeline })) : ("No Changes") })));
};
var VersionSelectDialogBody = function (_a) {
    var _b;
    var boxId = _a.boxId;
    var setTalentReviewVersion = useContext(GlobalStateContext).setTalentReviewVersion;
    var navigate = useNavigate();
    var response = useGetAvailableNineBoxes({ boxId: boxId });
    var versions = ((_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) || [];
    return (_jsx("div", __assign({ style: {
            width: "100%",
        } }, { children: _jsx(Stack, __assign({ direction: "vertical", flexGap: "m", fullWidth: true }, { children: versions.map(function (details, index) {
                var text = ((details === null || details === void 0 ? void 0 : details.versionName) || "") +
                    (index == 0 ? " - Current Version" : "");
                var onClick = index == 0
                    ? function () { return undefined; }
                    : function () {
                        setTalentReviewVersion(details.version);
                        navigate(getRoute("TalentReviewNineBoxRead").replace(":id", details.id));
                    };
                return (_jsx(VersionDialogBody, { summary: _jsx(VersionHeader, { text: text, onClick: onClick }), details: details, expanded: index == 0 }, "version-history-".concat(index)));
            }) })) })));
};
var VersionSelectDialog = function (_a) {
    var boxId = _a.boxId, onClose = _a.onClose;
    return (_jsx(Dialog, { header: "Version History", visible: true, body: _jsx(VersionSelectDialogBody, { boxId: boxId }), buttons: {
            cancelButtonProps: {
                text: "Cancel",
                onClick: onClose,
            },
        }, onClose: onClose }));
};
var NewVersionDialog = function (_a) {
    var version = _a.version, id = _a.id, onClose = _a.onClose;
    var setHoldUrl = useContext(GlobalStateContext).setHoldUrl;
    var navigate = useNavigate();
    var form = Form.useForm()[0];
    var _b = useState(""), formError = _b[0], setFormError = _b[1];
    var _c = useState(""), errorClass = _c[0], setErrorClass = _c[1];
    var newVersion = newVersionNineBoxQuery({
        substitutions: { id: id },
        invalidates: [
            { queryKey: ["{ninebox}/nine-box/".concat(id)] },
            { queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] },
        ],
    });
    var onVersionFormFinish = function (formValues) {
        newVersion.mutate({ versionName: formValues.name }, {
            onSuccess: function (data) {
                onClose();
                setHoldUrl(null);
                navigate(getRoute("TalentReviewNineBox").replace(":id", data.data.id));
            },
            onError: function (data) {
                var _a;
                if (data.message == "400") {
                    setFormError((_a = data.cause) === null || _a === void 0 ? void 0 : _a.versionName[0]);
                    setErrorClass(styles.formError);
                }
            },
        });
    };
    var versionValue = { name: "Version ".concat(version + 1) };
    var NewVersionForm = function () {
        return (_jsx(_Fragment, { children: _jsxs(Form, __assign({ form: form, layout: "vertical", autoComplete: "off", onFinish: onVersionFormFinish, initialValues: versionValue }, { children: [_jsx("div", { children: "Name this version to keep track of it in version history." }), _jsx(FormRequired, {}), _jsx(Form.Item, __assign({ label: "Name", name: "name", rules: [{ required: true, message: "Name is required" }], classNames: styles.labelEditFormInputWrapper, style: { marginBottom: "0" } }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill }) })), newVersion.isError && (_jsx("div", __assign({ role: "alert", className: "form-item-explain-error ".concat(errorClass) }, { children: formError })))] })) }));
    };
    return (_jsx(Dialog, { header: "Name current version", body: _jsx(NewVersionForm, {}), visible: true, buttons: {
            okButtonProps: {
                text: "Done",
                onClick: function () {
                    form.submit();
                },
                loading: newVersion.isPending,
            },
            cancelButtonProps: {
                text: "Cancel",
            },
        }, onClose: onClose }));
};
export var SettingsPageNavigator = function (_a) {
    var id = _a.id;
    var navigate = useNavigate();
    var openNineBoxSettingsPage = function () {
        navigate(getRoute("TalentReviewNineBoxSettings").replace(":id", id));
    };
    return (_jsx(IconButton, { iconPath: IconName.mdiCog, variant: IntelligenceButtonVariant.Neutral, onClick: function () { return openNineBoxSettingsPage(); } }));
};
var DeleteNineBoxDialog = function (_a) {
    var boxId = _a.boxId, onClose = _a.onClose, notify = _a.notify;
    var navigate = useNavigate();
    var setHoldUrl = useContext(GlobalStateContext).setHoldUrl;
    var deleteBoxId = deleteNineBoxQuery({
        substitutions: { boxId: boxId },
        invalidates: [{ queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] }],
    });
    return (_jsx(Dialog, { header: _jsx("div", __assign({ className: styles.loudText }, { children: "Are you sure you want to delete this 9 Box? This Action cannot be undone." })), visible: true, buttons: {
            okButtonProps: {
                text: "Confirm and Delete",
                onClick: function () {
                    deleteBoxId.mutate({}, {
                        onSuccess: function () {
                            setHoldUrl(undefined);
                            navigate(getRoute("DashboardTalentReview"));
                        },
                        onError: function () {
                            notify(InfoBarType.disruptive, "Error Deleting NineBox");
                        },
                    });
                },
            },
            cancelButtonProps: {
                text: "Cancel",
                onClick: onClose,
            },
        }, onClose: onClose }));
};
var VersionManagement = function (_a) {
    var _b;
    var id = _a.id, assignedBox = _a.assignedBox, notify = _a.notify;
    var nineBox = useGetFilteredNineBox({ id: id, assigned_box: assignedBox });
    var _c = useState(null), poppedDialog = _c[0], setPoppedDialog = _c[1];
    var _d = ((_b = nineBox.data) === null || _b === void 0 ? void 0 : _b.data) || {}, boxId = _d.boxId, version = _d.version;
    var unpopDialog = function () {
        setPoppedDialog(null);
    };
    var menuItem = function (item) {
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ onClick: function () { return setPoppedDialog(item.value); } }, { children: _jsx("div", __assign({ className: styles.menuItem }, { children: item.label })) }), item.value) }));
    };
    var menuOptionList = [
        {
            label: "See version history",
            value: _jsx(VersionSelectDialog, { boxId: boxId, onClose: unpopDialog }),
        },
        {
            label: "Name current version",
            value: (_jsx(NewVersionDialog, { version: version, id: id, onClose: unpopDialog })),
        },
        {
            label: "Delete",
            value: (_jsx(DeleteNineBoxDialog, { boxId: boxId, notify: notify, onClose: unpopDialog })),
        },
    ];
    var menuOptions = menuOptionList.map(function (item) { return menuItem(item); });
    return (_jsxs(_Fragment, { children: [_jsx(DropdownOctuple, __assign({ classNames: "my-dropdown-class", closeOnDropdownClick: true, dropdownClassNames: "my-dropdown-class", dropdownStyle: {}, initialFocus: true, offset: 0, overlay: menuOptions, placement: "bottom-start", positionStrategy: "absolute", style: {}, trigger: "click" }, { children: _jsx(IconButton, { ariaLabel: "Manage Versions", iconPath: IconName.mdiDotsVertical, variant: IntelligenceButtonVariant.Neutral }) })), poppedDialog &&
                createPortal(_jsx(ConfigProvider, { children: poppedDialog }), document.body)] }));
};
var NineBoxDownloadButton = function (_a) {
    var _b;
    var assignedBox = _a.assignedBox, id = _a.id;
    var nineBox = useGetFilteredNineBox({ id: id, assigned_box: assignedBox });
    var boxName = (((_b = nineBox.data) === null || _b === void 0 ? void 0 : _b.data) || {}).boxName;
    var getFilters = useContext(FilterContext).getFilters;
    var csvFilters = getFilters();
    if (assignedBox) {
        csvFilters.assigned_box = [assignedBox];
    }
    var baseFileName = generateFileName({ fileName: boxName, format: ".csv" });
    var url = "".concat(apiBase.ninebox, "/nine-box/").concat(encodeURIComponent(id), "/download");
    var filterString = getLeaderScoresParamString(csvFilters);
    if (filterString) {
        url += "?".concat(filterString);
    }
    return (_jsx(DownloadButton, { buttonText: "Download CSV", filename: baseFileName, url: url }));
};
var SavedStatus = function (_a) {
    var createdTs = _a.createdTs, updatedDate = _a.updatedDate, buttons = _a.buttons;
    var createdDate = createdTs ? formattedDate(createdTs) : "";
    var lastUpdatedDate = updatedDate ? formattedDate(updatedDate) : "";
    var lastUpdatedTime = updatedDate ? formattedTime(updatedDate) : "";
    var combineDateAndTime = lastUpdatedTime
        ? "".concat(lastUpdatedTime, " ").concat(lastUpdatedDate)
        : lastUpdatedDate;
    var updatedDateFormat = combineDateAndTime
        ? combineDateAndTime
        : createdDate;
    return (_jsx(Stack, __assign({ direction: "horizontal", fullWidth: true }, { children: _jsxs("div", __assign({ className: styles.statusRow }, { children: [_jsxs("div", { children: ["Created ", createdDate, " \u2022 Last updated ", updatedDateFormat] }), _jsx("div", __assign({ className: styles.statusRowButtons }, { children: buttons }))] })) })));
};
var RevertVersion = function (_a) {
    var id = _a.id;
    var setHoldUrl = useContext(GlobalStateContext).setHoldUrl;
    var revertVersion = revertVersionNineBoxQuery({
        substitutions: { id: id },
        invalidates: [
            { queryKey: ["{ninebox}/nine-box/".concat(id)] },
            { queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] },
        ],
    });
    var navigate = useNavigate();
    return (_jsx(_Fragment, { children: _jsx(Button, { text: "Restore as current version", onClick: function () {
                revertVersion.mutate({}, {
                    onSuccess: function (data) {
                        var _a;
                        var id = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.id;
                        if (!id) {
                            console.error("missing id from revert version returned payload");
                        }
                        var holdUrlSetter = function (previous) { return (__assign(__assign({}, previous), { urls: (previous.urls || []).concat(getFreelyBrowsableUrlExpressions([id])) })); };
                        setHoldUrl(holdUrlSetter);
                        setTimeout(function () {
                            navigate(getRoute("TalentReviewNineBox").replace(":id", id));
                        }, 0);
                    },
                });
            }, variant: IntelligenceButtonVariant.Neutral }) }));
};
export var getReadOnlySaverButtons = function (_a) {
    var id = _a.id;
    return [
        _jsxs(_Fragment, { children: [_jsx(RevertVersion, { id: id }), _jsx(DoneDialog, { id: id })] }),
    ];
};
var getAllAccessSaverButtons = function (_a) {
    var id = _a.id, notify = _a.notify;
    var assigned_box = useParams().assigned_box;
    return [
        _jsx(NineBoxDownloadButton, { id: id, assignedBox: assigned_box }),
        _jsx(SettingsPageNavigator, { id: id }),
        _jsx(SaveDialog, { id: id, notify: notify }),
        _jsx(VersionManagement, { id: id, notify: notify, assignedBox: assigned_box }),
    ];
};
var Saver = function (_a) {
    var _b;
    var id = _a.id, buttons = _a.buttons;
    var assigned_box = useParams().assigned_box;
    var assignedBox = assigned_box && String(Number(assigned_box) + 1);
    var nineBox = useGetFilteredNineBox({ id: id, assigned_box: assignedBox });
    var _c = ((_b = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _b === void 0 ? void 0 : _b.data) || {}, createdTs = _c.createdTs, lastUpdatedTs = _c.lastUpdatedTs;
    return (_jsx(_Fragment, { children: _jsx(SavedStatus, { buttons: buttons, createdTs: createdTs, updatedDate: lastUpdatedTs }) }));
};
var FixedBanner = function (_a) {
    var id = _a.id;
    var _b = useGetFilteredNineBox({ id: id }), isFetched = _b.isFetched, data = _b.data;
    var getMessage = function () {
        var _a;
        if (isFetched) {
            var notSaved = (((_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.leaders) || []).filter(function (_a) {
                var isSaved = _a.isSaved;
                return !isSaved;
            });
            if (notSaved.length == 0) {
                return "All leaders' positions have been saved";
            }
            else {
                return "".concat(notSaved.length, " leaders' positions have not been saved.");
            }
        }
        return "";
    };
    return (_jsx(_Fragment, { children: _jsx(InfoBar, { content: getMessage(), type: "neutral" }) }));
};
var UrlHolder = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var id = _a.id;
    var navigate = useNavigate();
    var location = useLocation();
    var _h = useContext(GlobalStateContext), getHoldUrl = _h.getHoldUrl, setHoldUrl = _h.setHoldUrl;
    var nineBoxParams = { id: id };
    var assigned_box = useParams().assigned_box;
    if (assigned_box) {
        nineBoxParams.assigned_box = Number(assigned_box) + 1;
    }
    var nineBox = useGetFilteredNineBox(nineBoxParams);
    var boxId = (_c = (_b = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.boxId;
    var availableBoxes = useGetAvailableNineBoxes({ boxId: boxId }, { shouldSkipCall: function () { return !boxId; } });
    var notSaved = (((_e = (_d = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.leaders) || []).filter(function (_a) {
        var isSaved = _a.isSaved;
        return !isSaved;
    });
    var availableBoxIds = (((_f = availableBoxes === null || availableBoxes === void 0 ? void 0 : availableBoxes.data) === null || _f === void 0 ? void 0 : _f.data) || []).map(function (_a) {
        var id = _a.id;
        return id;
    });
    useEffect(function () {
        var onBeforeUnload = function (e) {
            if (notSaved.length > 0) {
                e.preventDefault();
                e.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", onBeforeUnload);
        return function () {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [notSaved]);
    useEffect(function () {
        // If the unsaved leaders change, rebuild the hold dialog
        setHoldUrl(undefined);
    }, [JSON.stringify(notSaved)]);
    var addNineBoxAuditLog = addNineBoxAuditLogQuery({
        substitutions: { id: id },
        invalidates: [{ queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] }],
    });
    var updateBoxLeaders = updateNineBoxLeadersQuery({
        substitutions: { id: id },
        invalidates: [
            { queryKey: ["{ninebox}/nine-box/".concat(id)] },
            { queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] },
        ],
        events: {
            onError: function () {
                notify(InfoBarType.disruptive, "Error saving box leaders");
            },
            onSuccess: function () {
                addNineBoxAuditLog.mutate({
                    updateType: NineBoxAuditLogUpdateType.SaveLeaders,
                    updateCount: notSaved.length,
                });
            },
        },
    });
    var HeaderText = function () {
        var _a, _b;
        var notSaved = (((_b = (_a = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.leaders) || []).filter(function (_a) {
            var isSaved = _a.isSaved;
            return !isSaved;
        });
        return (_jsx(_Fragment, { children: "Placement for ".concat(notSaved.length, " leader").concat(notSaved.length == 1 ? "" : "s", " has not been saved") }));
    };
    if (availableBoxIds.length > 0 &&
        notSaved.length > 0 &&
        ((_g = getHoldUrl()) === null || _g === void 0 ? void 0 : _g.onUrl) != (location === null || location === void 0 ? void 0 : location.pathname)) {
        var holdUrlTexts_1 = {
            url: location === null || location === void 0 ? void 0 : location.pathname,
            header: _jsx(HeaderText, {}),
            body: "Unsaved changes will be lost if you exit before saving",
            okText: "Save and exit",
            cancelText: "Exit without saving",
        };
        setHoldUrl({
            onUrl: location === null || location === void 0 ? void 0 : location.pathname,
            urls: getFreelyBrowsableUrlExpressions(availableBoxIds.concat([id])),
            dialog: (_jsx(Dialog, { header: holdUrlTexts_1.header, body: holdUrlTexts_1.body, visible: true, buttons: {
                    okButtonProps: {
                        text: holdUrlTexts_1.okText,
                        onClick: function () {
                            updateBoxLeaders.mutate({
                                boxLeaders: notSaved.map(function (_a) {
                                    var leaderProfileId = _a.leaderProfileId;
                                    return ({
                                        leaderProfileId: leaderProfileId,
                                        isSaved: true,
                                    });
                                }),
                            });
                            setHoldUrl(undefined);
                        },
                    },
                    cancelButtonProps: {
                        text: holdUrlTexts_1.cancelText,
                        onClick: function () {
                            setHoldUrl(undefined);
                        },
                    },
                }, onClose: function () {
                    navigate(holdUrlTexts_1.url);
                } })),
        });
    }
    else if (notSaved.length == 0) {
        setHoldUrl(undefined);
    }
    return _jsx(_Fragment, {});
};
var LeaderPanelInfoSet = function (_a) {
    var title = _a.title, loud = _a.loud, infos = _a.infos;
    return (_jsxs("div", { children: [title && _jsx("div", __assign({ className: styles.leaderPanelTitle }, { children: title })), _jsx("div", __assign({ className: styles.leaderPanelLoud }, { children: loud })), (infos || []).map(function (info) { return (_jsx("div", __assign({ className: styles.leaderPanelInfo }, { children: info }))); })] }));
};
var DropdownLeaderBox = function (_a) {
    var assignedBox = _a.assignedBox, options = _a.options;
    return (_jsx(Dropdown, { options: options === null || options === void 0 ? void 0 : options.map(function (option) { return (__assign(__assign({}, option), { value: option.assignment })); }), initial: assignedBox, iconOverrideProps: {
            style: {
                position: "absolute",
                left: "-4px",
            },
        }, dropdownOverrideProps: {
            style: { width: "396px" },
        }, buttonOverrideProps: {
            classNames: styles.leaderPanelButton,
            shape: ButtonShape.Rectangle,
            variant: ButtonVariant.Secondary,
            buttonWidth: ButtonWidth.fill,
            size: ButtonSize.Medium,
            alignIcon: ButtonIconAlign.Right,
            alignText: ButtonTextAlign.Left,
        } }, assignedBox));
};
var LeaderPanel = function (_a) {
    var _b, _c, _d;
    var leader = _a.leader, id = _a.id, leaderBoxFactory = _a.leaderBoxFactory, onClose = _a.onClose, notify = _a.notify;
    var nineBoxParams = { id: id };
    var assigned_box = useParams().assigned_box;
    if (assigned_box) {
        nineBoxParams.assigned_box = Number(assigned_box) + 1;
    }
    var nineBox = useGetFilteredNineBox(nineBoxParams);
    var _e = useState(false), moved = _e[0], setMoved = _e[1];
    var _f = useState(null), dialog = _f[0], setDialog = _f[1];
    var _g = ((_c = (_b = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.leaders.find(function (_a) {
        var leaderProfileId = _a.leaderProfileId;
        return leaderProfileId == leader.leaderProfileId;
    })) || leader, efId = _g.efId, firstName = _g.firstName, lastName = _g.lastName, isMoved = _g.isMoved, assignedBox = _g.assignedBox, timeInRoleYears = _g.timeInRoleYears, movedNotes = _g.movedNotes, movedReason = _g.movedReason;
    var handleEightfoldUserNavigation = useEightfold().handleEightfoldUserNavigation;
    if (isMoved && !moved) {
        setMoved(true);
    }
    var fullName = "".concat(firstName || "", " ").concat(lastName || "").trim();
    var initials = ((firstName || "").slice(0, 1) + (lastName || "").slice(0, 1)).toUpperCase();
    var onChange = function (box) { return function () {
        if (box == assignedBox) {
            return;
        }
        movedLeader = __assign({}, leader);
        movedLeader.recommendedBox = box;
        setDialog(_jsx(ShowMovementDialog, { notify: notify, onClose: function () { return setDialog(null); }, onDone: function () { return setDialog(null); } }));
    }; };
    var options = (_d = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _d === void 0 ? void 0 : _d.data.boxes.map(function (box, index) { return ({
        label: box.name,
        assignment: index,
        onChange: onChange(index),
    }); });
    var jobInfos = [];
    var title = leader.roleName;
    if (title) {
        jobInfos.push(title);
    }
    if (timeInRoleYears) {
        jobInfos.push("".concat(timeInRoleYears, " years"));
    }
    var recommendedLabels = leader.getRecommendedLabels();
    var performanceRatingScore = "".concat(leader === null || leader === void 0 ? void 0 : leader.performanceScore.toFixed(1), "/").concat(leader === null || leader === void 0 ? void 0 : leader.getPerformanceMax().toFixed(1));
    return (dialog || (_jsxs(Panel, __assign({ placeholder: null, size: PanelSize.small, visible: true, onClose: onClose, headerClassNames: styles.panelHeader }, { children: [_jsx("div", __assign({ className: styles.leaderViewProfile }, { children: _jsx(Button, { text: "View profile", variant: IntelligenceButtonVariant.Neutral, onClick: function () { return handleEightfoldUserNavigation({ ef_id: efId }); } }) })), _jsxs(Stack, __assign({ direction: "vertical", flexGap: "xs" }, { children: [_jsx(Avatar, __assign({ size: "100px", fontSize: "32px", classNames: styles.avatar }, { children: _jsx("div", __assign({ className: styles.avatarInner }, { children: initials })) })), _jsxs(Card, __assign({ height: "auto", width: 420, size: CardSize.Flex, bordered: true }, { children: [_jsx("div", { className: styles.leaderAvatarSpacer }), _jsx(LeaderPanelInfoSet, { loud: _jsx(Stack, __assign({ flexGap: "s" }, { children: [fullName, moved && _jsx(ArrangeBringForwardIcon, {})] })), infos: jobInfos })] })), _jsx(Card, __assign({ height: "auto", width: 420, size: CardSize.Flex, bordered: true, classNames: styles.leaderBottomCard }, { children: _jsxs(Stack, __assign({ direction: "vertical", gap: "l" }, { children: [_jsx(LeaderPanelInfoSet, { title: "9 Box (Assigned)", loud: createElement(leaderBoxFactory || DropdownLeaderBox, { assignedBox: assignedBox, options: options }) }), _jsx(LeaderPanelInfoSet, { title: "9 Box (Recommended)", loud: leader.getRecommendedLabels().name }), _jsx(LeaderPanelInfoSet, { title: "Leadership Performance (Scores)", loud: recommendedLabels.xDataSources == "Performance"
                                        ? "".concat(recommendedLabels.xPlace, " (").concat(performanceRatingScore, ")")
                                        : "".concat(recommendedLabels.yPlace, " (").concat(performanceRatingScore, ")"), infos: leader.performanceRatings.map(function (_a) {
                                        var endDate = _a.endDate, ratingDescription = _a.ratingDescription;
                                        return "".concat(endDate.getFullYear(), ": ").concat(ratingDescription);
                                    }) || [] }), _jsx(LeaderPanelInfoSet, { title: "Leadership Trajectory (Scores)", loud: recommendedLabels.xDataSources == "Performance"
                                        ? recommendedLabels.yPlace
                                        : recommendedLabels.xPlace, infos: [
                                        "Capability: ".concat(leader.trajectoryScores[BoxLeaderScoreType.Capabilities]
                                            .categoricalResult, " (").concat(leader.trajectoryScores[BoxLeaderScoreType.Capabilities].score.toFixed(1), "/").concat(leader
                                            .getTrajectoryMax()
                                            .toFixed(1), ")"),
                                        "Potential: ".concat(leader.trajectoryScores[BoxLeaderScoreType.Potential]
                                            .categoricalResult, " (").concat(leader.trajectoryScores[BoxLeaderScoreType.Potential].score.toFixed(1), "/").concat(leader
                                            .getTrajectoryMax()
                                            .toFixed(1), ")"),
                                    ] || [] }), leader.movedReason && (_jsx(LeaderPanelInfoSet, { title: "Reason for Movement", loud: movedReason, infos: movedNotes ? [movedNotes] : [] }))] })) }))] }))] }))));
};
var sanitizeId = function (id) { return (id.match(/[-a-fA-F0-9]+/) || [])[0] || ""; };
var getFreelyBrowsableUrlExpressions = function (ids) {
    return [
        "TalentReviewNineBox",
        "TalentReviewNineBoxRead",
        "TalentReviewNineBoxLeaderView",
        "TalentReviewNineBoxLeaderReadView",
        "TalentReviewNineBoxSettings",
    ]
        .map(getRoute)
        .reduce(function (current, route) { return __spreadArray(__spreadArray([], (route.includes(":id")
        ? ids.map(function (id) {
            return route
                .replace("/", "\\/")
                .replace(":id", sanitizeId(id))
                .replace(":assigned_box", "\\d+");
        })
        : [route.replace("/", "\\/").replace(":assigned_box", "\\d+")]), true), current, true); }, [])
        .map(function (re) { return new RegExp(re); });
};
var nineBoxFilters = new Set(DefaultFilterSet);
nineBoxFilters.delete(AvailableFilters.Manager);
nineBoxFilters.delete(AvailableFilters.Gender);
nineBoxFilters.delete(AvailableFilters.Ethnicity);
nineBoxFilters.delete(AvailableFilters.NineBox);
export var SpecifiedNineBox = function (_a) {
    var id = _a.id, extraPillProps = _a.extraPillProps, content = _a.content, getLeaderBoxForPopup = _a.getLeaderBoxForPopup, getLeaderBoxForTable = _a.getLeaderBoxForTable, getSaverButtons = _a.getSaverButtons, heading = _a.heading, getSeeAllLeadersUrl = _a.getSeeAllLeadersUrl;
    var _b = useState(null), shownLeader = _b[0], setShownLeader = _b[1];
    return (_jsxs(Stack, __assign({ fullWidth: true, direction: "vertical", "data-testid": "NineBox" }, { children: [shownLeader && (_jsx(LeaderPanel, { leader: shownLeader, onClose: function () {
                    setShownLeader(null);
                }, leaderBoxFactory: getLeaderBoxForPopup, id: id, notify: notify })), _jsx(Saver, { buttons: (getSaverButtons || getAllAccessSaverButtons)({ id: id, notify: notify }), id: id }), heading, _jsx("div", __assign({ className: styles.filters }, { children: _jsx(Filters, { availableFilters: nineBoxFilters }) })), createElement(content, {
                setShownLeader: setShownLeader,
                getLeaderBoxForTable: getLeaderBoxForTable,
                extraPillProps: extraPillProps,
                id: id,
                notify: notify,
                getSeeAllLeadersUrl: getSeeAllLeadersUrl,
            }), _jsx(UrlHolder, { id: id })] })));
};
