var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box } from "@mui/material";
import { defaultColorsToUse } from "../DonutChart/DonutChart";
var Circle = function (_a) {
    var border = _a.border, background = _a.background;
    return (_jsx(Box, { sx: {
            minWidth: "24px",
            height: "24px",
            borderRadius: "12px",
            border: "1px solid ".concat(border),
            background: background,
        } }));
};
var InsidePiece = function (_a) {
    var color = _a.color, description = _a.description, count = _a.count, percentage = _a.percentage, isLast = _a.isLast;
    return (_jsxs(Box, __assign({ "data-testid": "legend-item", sx: __assign(__assign({ display: "flex", width: "300px", height: "100px", radius: "12px" }, (isLast ? {} : { borderBottom: "1px solid #D9DCE1" })), { padding: "16px 0", gap: "8px" }) }, { children: [_jsx(Circle, __assign({}, color)), _jsx(Box, __assign({ sx: {
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontFamily: "Avenir Book",
                } }, { children: description })), _jsxs(Box, __assign({ sx: {
                    fontWeight: 600,
                    fontFamily: "Avenir Book",
                    fontSize: "16px",
                    lineHeight: "24px",
                } }, { children: ["(", count, ")"] })), _jsx(Box, { sx: { height: "24px", borderLeft: "1px solid #D9DCE1" } }), _jsxs(Box, __assign({ sx: {
                    fontWeight: 400,
                    fontFamily: "Avenir Heavy",
                    fontSize: "16px",
                    lineHeight: "24px",
                    paddingTop: "1px",
                } }, { children: [percentage.toFixed(0), "%"] }))] })));
};
var VerticalSet = function (_a) {
    var elements = _a.elements;
    return (_jsx(Box, { children: elements.map(function (_a, index) {
            var color = _a.color, description = _a.description, count = _a.count, percentage = _a.percentage;
            return (_jsx(InsidePiece, { color: color, description: description, count: count, percentage: percentage, isLast: !((index + 1) % elements.length) }));
        }) }));
};
export var DiversityLegend = function (_a) {
    var elements = _a.elements, circleColors = _a.circleColors;
    var activeColors = circleColors || defaultColorsToUse;
    var total = elements.reduce(function (total, _a) {
        var count = _a.count;
        return total + count;
    }, 0);
    var elementsCopy = __spreadArray([], elements, true);
    elementsCopy.sort(function (_a, _b) {
        var a = _a.count;
        var b = _b.count;
        return a == b ? 0 : a < b ? 1 : -1;
    });
    var coloreds = elementsCopy.map(function (element, index) { return (__assign(__assign({}, element), { color: activeColors[index % activeColors.length], percentage: (100 * element.count) / total })); });
    var leftElements = coloreds.slice(0, Math.ceil(coloreds.length / 2));
    var rightElements = coloreds.slice(Math.ceil(coloreds.length / 2));
    return (_jsxs(Box, __assign({ "data-testid": "DiversityLegend", sx: {
            display: "flex",
            alignItems: "flex-start",
            borderRadius: "16px",
            padding: "8px 32px",
            gap: "16px",
        } }, { children: [_jsx(VerticalSet, { elements: leftElements }), _jsx(VerticalSet, { elements: rightElements })] })));
};
