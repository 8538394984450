// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d2h4XWb0KamW0j7U2jNX {
  top: 1px;
}
.d2h4XWb0KamW0j7U2jNX span {
  color: var(--grey-color-70);
  font-family: "Avenir Medium", sans-serif;
  font-size: 16px;
  font-weight: initial !important;
}`, "",{"version":3,"sources":["webpack://./src/components/ResetButton/ResetButton.module.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;AACF;AACE;EACE,2BAAA;EACA,wCAAA;EACA,eAAA;EACA,+BAAA;AACJ","sourcesContent":[".resetButton {\n  top: 1px;\n\n  span {\n    color: var(--grey-color-70);\n    font-family: \"Avenir Medium\", sans-serif;\n    font-size: 16px;\n    font-weight: initial !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetButton": `d2h4XWb0KamW0j7U2jNX`
};
export default ___CSS_LOADER_EXPORT___;
