var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, styled, useTheme } from "@mui/material";
import LevelFunctionMatrix from "./LevelFunctionMatrix";
import { useHeatmapLeadershipScores } from "../../hooks/heatmapScores";
import { useNavigate } from "../../hooks/navigate";
import { useContext } from "react";
import { FilterContext } from "../../providers/FilterProvider";
import { Status } from "../../common/action";
import { getRoute } from "../../routes/getAllRoutes";
var withFocusedBorder = function (outlineColor) { return ({
    border: "1px solid ".concat(outlineColor),
    "&:focus": {
        border: "8px solid ".concat(outlineColor),
    },
}); };
var onEnterKey = function (toDo) { return function (event) {
    if ((event === null || event === void 0 ? void 0 : event.code) != "Enter") {
        return;
    }
    toDo();
}; };
var cellHeight = "58px";
export var ScoreTypes;
(function (ScoreTypes) {
    ScoreTypes["Capability"] = "capability";
    ScoreTypes["Potential"] = "potential";
    ScoreTypes["Functional"] = "functional";
    ScoreTypes["Organizational"] = "organizational";
    ScoreTypes["AI"] = "ai_score";
    ScoreTypes["Heidrick"] = "heidrick_score";
    ScoreTypes["Overall"] = "overall_score";
})(ScoreTypes || (ScoreTypes = {}));
export var getLeadershipDataCell = function (scoreFunctor, formatScore, scoreType, getScoreColor, secondaryPageUrl) {
    return function (_a) {
        var getNameByLookupCode = _a.getNameByLookupCode, level = _a.level, roles = _a.roles, isActive = _a.isActive, score = _a.score, expandCell = _a.expandCell;
        var Component = function () {
            var updateFilters = useContext(FilterContext).updateFilters;
            var navigate = useNavigate();
            var theme = useTheme();
            var heading = roles[1]
                ? "".concat(getNameByLookupCode(roles[1]), " with Sub-Function ").concat(getNameByLookupCode(roles[0]))
                : getNameByLookupCode(roles[0]);
            var ariaLabel = "".concat(formatScore(scoreFunctor(score)), " ").concat(getNameByLookupCode(level), " doing ").concat(heading);
            var colorsForAction = {
                act: {
                    background: theme.palette.error.main,
                    border: theme.palette.error.dark,
                    color: theme.palette.text.primary,
                },
                watch: {
                    background: theme.palette.warning.main,
                    border: theme.palette.warning.dark,
                    color: theme.palette.text.primary,
                },
                maintain: {
                    background: theme.palette.success.main,
                    border: theme.palette.success.dark,
                    color: theme.palette.text.primary,
                },
                nodata: {
                    background: theme.palette.grey.A400,
                    border: theme.palette.primary.dark + "20",
                },
            };
            var action = getScoreColor(score);
            var _a = colorsForAction[action], borderColor = _a.border, backgroundColor = _a.background, color = _a.color;
            var borderStyle = isActive()
                ? { border: "none" }
                : withFocusedBorder(borderColor);
            var StyledCell = styled(Box)(__assign(__assign({ background: backgroundColor, color: color, borderRadius: "14px" }, borderStyle), { "&:hover": __assign({}, (action == "nodata"
                    ? {}
                    : { outline: "2px solid ".concat(borderColor) })), "&:focus": {
                    outline: "none",
                    border: "3px solid ".concat(borderColor),
                    position: "relative",
                    bottom: "2px",
                    right: "2px",
                }, width: "100%", height: cellHeight, font: "800 16px/24px Avenir Medium", display: "flex", justifyContent: "center", alignItems: "center" }));
            var onClick = function () {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                var filters = {
                    scoreType: scoreType,
                };
                roles.forEach(function (item) {
                    if (item.startsWith("F")) {
                        filters["functionFilter"] = [item];
                    }
                    if (item.startsWith("SF")) {
                        filters["subFunctionFilter"] = [item];
                    }
                });
                // if no level is selected, don't add it.
                if (!level.toLowerCase().startsWith("all")) {
                    filters["level"] = level;
                }
                updateFilters(filters);
                navigate(secondaryPageUrl || getRoute("AssessmentAll"));
            };
            return (_jsx(StyledCell, __assign({}, (ariaLabel ? { "aria-label": ariaLabel } : {}), { tabIndex: 0, onClick: action == "nodata" ? function () { return null; } : onClick, onKeyPress: onEnterKey(expandCell) }, { children: formatScore(scoreFunctor(score)) })));
        };
        return _jsx(Component, {});
    };
};
var scoreFunctor = function (field) {
    return function (score) {
        if ((score === null || score === void 0 ? void 0 : score[field]) == null) {
            return;
        }
        return score === null || score === void 0 ? void 0 : score[field];
    };
};
var atRiskScoreFormatter = function (field) {
    return function (score) {
        var cell = score === null || score === void 0 ? void 0 : score[field];
        if ((cell === null || cell === void 0 ? void 0 : cell.value) == null) {
            return;
        }
        return "".concat(Number(cell === null || cell === void 0 ? void 0 : cell.value).toFixed(0), "% (").concat(Number(cell === null || cell === void 0 ? void 0 : cell.count).toFixed(0), ")");
    };
};
var atRiskStateFunctor = function (field) { return function (score) { var _a; return ((_a = score === null || score === void 0 ? void 0 : score[field]) === null || _a === void 0 ? void 0 : _a.state) || Status.NoData; }; };
var stateFunctor = function (field) {
    return function (score) {
        var _a, _b;
        if (((_a = score === null || score === void 0 ? void 0 : score[field]) === null || _a === void 0 ? void 0 : _a.state) == null) {
            return "nodata";
        }
        return (_b = score === null || score === void 0 ? void 0 : score[field]) === null || _b === void 0 ? void 0 : _b.state;
    };
};
export var starsScoreFormatter = function (cell) {
    if (cell == null || cell.value == null || isNaN(cell.value)) {
        return;
    }
    return Number(cell === null || cell === void 0 ? void 0 : cell.value).toFixed(2);
};
export var percentageScoreFormatter = function (cell) {
    if (cell == null || cell.value == null || isNaN(cell.value)) {
        return;
    }
    return "".concat(Number(cell === null || cell === void 0 ? void 0 : cell.value).toFixed(0), "%");
};
export var percentageOfScoreFormatterBase = function (cell, totalCount) {
    if (cell == null) {
        return;
    }
    if (totalCount == null) {
        totalCount =
            Math.round((100 * Number(cell.count)) / Number(cell.value)) || 0;
    }
    return (_jsxs(Box, __assign({ component: "span" }, { children: [_jsx(Box, __assign({ component: "span" }, { children: "".concat(Number(cell.value).toFixed(0), "%") })), _jsx(Box, __assign({ component: "span", sx: { fontSize: "13px" } }, { children: " (".concat(Number(cell.count).toFixed(0), " of ").concat(totalCount, ")") }))] })));
};
export var percentageAndCountOfScoreFormatter = function (score, total) {
    if (score == null) {
        return;
    }
    return "".concat(Number(100 * (score / total)).toFixed(0), "% (").concat(Number(score).toFixed(0));
};
var RiskTitleLevelFunctionMatrix = function (props) {
    return (_jsx(LevelFunctionMatrix, __assign({}, props, { highRiskTitle: {
            teams: "Teams with significant gaps",
            individual: "Leaders with significant gaps",
        } })));
};
export var CapabilityLevelFunctionMatrix = function () { return (_jsx(RiskTitleLevelFunctionMatrix, { useHeatmapScores: useHeatmapLeadershipScores("Capabilities"), getExpandedCell: function () { return null; }, getDataCell: getLeadershipDataCell(scoreFunctor("capability"), percentageScoreFormatter, ScoreTypes.Capability, stateFunctor("capability")) })); };
export var PotentialLevelFunctionMatrix = function () { return (_jsx(RiskTitleLevelFunctionMatrix, { useHeatmapScores: useHeatmapLeadershipScores("Potential"), getExpandedCell: function () { return null; }, getDataCell: getLeadershipDataCell(scoreFunctor("potential"), percentageScoreFormatter, ScoreTypes.Potential, stateFunctor("potential")) })); };
export var OrganizationalLevelFunctionMatrix = function () { return (_jsx(RiskTitleLevelFunctionMatrix, { useHeatmapScores: useHeatmapLeadershipScores("Organizational"), getExpandedCell: function () { return null; }, getDataCell: getLeadershipDataCell(scoreFunctor("organizational"), percentageScoreFormatter, ScoreTypes.Organizational, stateFunctor("organizational")) })); };
export var FunctionalLevelFunctionMatrix = function () { return (_jsx(RiskTitleLevelFunctionMatrix, { useHeatmapScores: useHeatmapLeadershipScores("Functional"), getExpandedCell: function () { return null; }, getDataCell: getLeadershipDataCell(scoreFunctor("functional"), percentageScoreFormatter, ScoreTypes.Functional, stateFunctor("functional")) })); };
export var AiScoreLevelFunctionMatrix = function () { return (_jsx(RiskTitleLevelFunctionMatrix, { useHeatmapScores: useHeatmapLeadershipScores("AI"), getExpandedCell: function () { return null; }, getDataCell: getLeadershipDataCell(scoreFunctor("ai_score"), starsScoreFormatter, ScoreTypes.AI, stateFunctor("ai_score")) })); };
export var HeidrickScoreLevelFunctionMatrix = function () { return (_jsx(RiskTitleLevelFunctionMatrix, { useHeatmapScores: useHeatmapLeadershipScores("Heidrick"), getExpandedCell: function () { return null; }, getDataCell: getLeadershipDataCell(scoreFunctor("heidrick_score"), starsScoreFormatter, ScoreTypes.Heidrick, stateFunctor("heidrick_score")) })); };
export var OverallScoreLevelFunctionMatrix = function () { return (_jsx(RiskTitleLevelFunctionMatrix, { useHeatmapScores: useHeatmapLeadershipScores("Match"), getExpandedCell: function () { return null; }, getDataCell: getLeadershipDataCell(scoreFunctor("overall_score"), starsScoreFormatter, ScoreTypes.Overall, stateFunctor("overall_score")) })); };
export function LowPerformerLevelFunctionMatrix(_a) {
    var scoreType = _a.scoreType, useScores = _a.useScores, scoreNormalizer = _a.scoreNormalizer, highRiskTitle = _a.highRiskTitle, highRiskTitleFormatter = _a.highRiskTitleFormatter, onClickIndividualRisk = _a.onClickIndividualRisk, secondaryPageUrl = _a.secondaryPageUrl;
    var normalizedScore = (scoreNormalizer || (function (value) { return value; }))(scoreType);
    return (_jsx(LevelFunctionMatrix, { useHeatmapScores: useScores(normalizedScore), getExpandedCell: function () { return null; }, getDataCell: getLeadershipDataCell(function (value) { return value; }, atRiskScoreFormatter(scoreType), scoreType, atRiskStateFunctor(scoreType), secondaryPageUrl), highRiskTitle: highRiskTitle, highRiskTitleFormatter: highRiskTitleFormatter, secondaryPageUrl: secondaryPageUrl, onClickIndividualRisk: onClickIndividualRisk }));
}
