var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { Box, styled, useTheme, List, ListItem } from "@mui/material";
import { Button, ButtonIconAlign, ButtonShape, ButtonSize, ButtonVariant, Dropdown as OctupleDropdown, IconName, } from "@eightfold.ai/octuple";
import CheckBox from "../CheckBox/CheckBox";
import { filterListeners, updateFilters } from "../../providers/FilterProvider";
function determineInitialState(initial) {
    var r = tmpDetermineInitialState(initial);
    return r;
}
function tmpDetermineInitialState(initial) {
    if (Array.isArray(initial)) {
        return initial
            .filter(function (value) { return value; })
            .map(function (selected) { return (selected.value ? selected.value : selected); });
    }
    else if (initial) {
        return [initial];
    }
    else {
        return [];
    }
}
export var DropdownButton = function (_a) {
    var label = _a.label, iconOverrideProps = _a.iconOverrideProps, buttonProps = _a.buttonProps, alignIcon = _a.alignIcon, buttonOverrideProps = _a.buttonOverrideProps, onClick = _a.onClick;
    return (_jsx(Button, __assign({ ariaLabel: "Dropdown Button", classNames: "dropdown-button", shape: ButtonShape.Pill, size: ButtonSize.Medium, text: "".concat(label || ""), onClick: onClick, alignIcon: alignIcon || ButtonIconAlign.Right, iconProps: __assign({ ariaHidden: true, "data-testid": "dropdown-".concat(label, "-icon"), path: IconName.mdiChevronDown, role: "presentation", rotate: 0, spin: false, vertical: false }, (iconOverrideProps || {})) }, buttonProps, (buttonOverrideProps || {}))));
};
var Dropdown = function (_a) {
    var _b;
    var rawOptions = _a.options, initial = _a.initial, label = _a.label, inactiveLabel = _a.inactiveLabel, multiple = _a.multiple, onChangeMultiple = _a.onChangeMultiple, renderCounter = _a.renderCounter, buttonOverrideProps = _a.buttonOverrideProps, dropdownOverrideProps = _a.dropdownOverrideProps, iconOverrideProps = _a.iconOverrideProps, loading = _a.loading, rerenderMe = _a.rerenderMe;
    var theme = useTheme();
    var mounted = React.useRef(false);
    var counterOverrideRef = useRef(null);
    var options = __spreadArray([], rawOptions.map(function (option) {
        return (option === null || option === void 0 ? void 0 : option.value) ? option : __assign(__assign({}, option), { value: option.label });
    }), true);
    options.sort(function (_a, _b) {
        var a = _a.label;
        var b = _b.label;
        return (a == b ? 0 : a > b ? 1 : -1);
    });
    var _c = useState(determineInitialState(initial)), selected = _c[0], setSelected = _c[1];
    var handleChangeSelect = function () {
        if (!mounted.current) {
            mounted.current = true;
            // Only run onChange after the component has been mounted
            return;
        }
        if (multiple) {
            if (onChangeMultiple) {
                onChangeMultiple(selected);
            }
        }
        else {
            var selectedOption = options === null || options === void 0 ? void 0 : options.filter(function (_a) {
                var value = _a.value;
                return value == selected[0];
            })[0];
            if (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.onChange) {
                selectedOption.onChange();
            }
        }
    };
    useEffect(handleChangeSelect, [selected]);
    useEffect(function () {
        if (JSON.stringify(determineInitialState(initial)) != JSON.stringify(selected)) {
            setSelected(determineInitialState(initial));
        }
    }, [initial]);
    useEffect(function () {
        var rerenderListener = function (_, _a) {
            var rerenderKey = _a.rerenderKey;
            if (!rerenderKey) {
                return;
            }
            rerenderMe && rerenderMe();
        };
        filterListeners.add(rerenderListener);
        return function () {
            filterListeners.delete(rerenderListener);
        };
    }, []);
    var handleClick = function (value) {
        if (multiple) {
            var filtered = selected.filter(function (on) { return on != value; });
            if (filtered.length != selected.length) {
                filtered.sort();
                setSelected(filtered);
                return filtered;
            }
            var current = __spreadArray(__spreadArray([], selected, true), [value], false);
            current.sort();
            setSelected(current);
        }
        else {
            setSelected([value]);
        }
    };
    var buttonProps = {
        variant: ButtonVariant.Neutral,
        counter: "",
    };
    if (selected.length) {
        buttonProps.variant = ButtonVariant.Primary;
    }
    if (selected.length === 1 &&
        (options === null || options === void 0 ? void 0 : options.find(function (_a) {
            var value = _a.value;
            return value == selected[0];
        }))) {
        // Don't make the button primary if the selected option is inactive
        if (((_b = options === null || options === void 0 ? void 0 : options.find(function (_a) {
            var value = _a.value;
            return value == selected[0];
        })) === null || _b === void 0 ? void 0 : _b.label) ===
            inactiveLabel) {
            buttonProps.variant = ButtonVariant.Neutral;
        }
    }
    var defaultRenderCounter = function (options, selectedValues) {
        var _a;
        var counterOverride = counterOverrideRef.current;
        if (counterOverride != null) {
            return counterOverride;
        }
        if (selectedValues.length === 0 && multiple) {
            return inactiveLabel || "";
        }
        if (selectedValues.length === 1 &&
            (options === null || options === void 0 ? void 0 : options.find(function (_a) {
                var value = _a.value;
                return value == selectedValues[0];
            }))) {
            return "".concat((_a = options === null || options === void 0 ? void 0 : options.find(function (_a) {
                var value = _a.value;
                return value == selectedValues[0];
            })) === null || _a === void 0 ? void 0 : _a.label);
        }
        if (selectedValues.length) {
            return selected.length.toString();
        }
        return "";
    };
    buttonProps.counter = renderCounter
        ? renderCounter(options || [], selected || [])
        : defaultRenderCounter(options || [], selected || []);
    var DropdownItem = function (_a) {
        var label = _a.label, value = _a.value;
        var DropdownItemContainer = styled(ListItem)({
            display: "flex",
            alignItems: "center",
            padding: "6px 12px",
            cursor: "pointer",
        });
        var hoverStyles = {};
        if (!multiple) {
            hoverStyles = {
                "&:hover": {
                    backgroundColor: "#D5D9DE",
                    borderRadius: "10px",
                },
            };
        }
        return (_jsx(DropdownItemContainer, __assign({ sx: hoverStyles, "data-test-id": "dropdown-item-".concat(value), onClick: function () {
                handleClick(value);
            } }, { children: _jsx(CheckBox, { value: "".concat(value), label: label, checked: selected.includes(value) }) })));
    };
    var dropdownOptions = function () {
        var listStyles = {};
        if (!multiple) {
            listStyles = {
                "&.dropdown-list [class^='checkmark']": {
                    display: "none",
                },
                "&.dropdown-list [class^='selector-label']": {
                    marginLeft: "0",
                },
            };
        }
        return (_jsx(List, __assign({ className: "dropdown-list", sx: listStyles }, { children: options === null || options === void 0 ? void 0 : options.map(function (_a) {
                var value = _a.value, label = _a.label;
                return (_jsx(DropdownItem, { label: label, value: value }, "item-".concat(value, "-").concat(label)));
            }) })));
    };
    var extraBadgeStyles = {};
    if (!label) {
        // Fix a bug where the counter text is flipped when no label is provided
        extraBadgeStyles = {
            ".dropdown [class^='badge'], .dropdown [class*=' badge']": {
                transform: "scaleX(-1)",
            },
        };
    }
    return (_jsx(Box, __assign({ sx: __assign({ "--button-font-family": "".concat(theme.typography.button.fontFamily), "--badge-font-family": "".concat(theme.typography.button.fontFamily), ".dropdown-button": {
                padding: "0 12px",
            }, ".dropdown-button [class^='button-text']": {
                fontWeight: "initial",
            }, ".dropdown [class^='counter'], .dropdown [class*=' counter']": {
                fontSize: "16px",
                fontWeight: "initial",
                height: "24px",
                lineHeight: "24px",
                padding: "0 6px",
                paddingTop: "1px",
                backgroundColor: "white",
                borderRadius: "4px",
            } }, extraBadgeStyles) }, { children: _jsx(OctupleDropdown, __assign({ initialFocus: true, classNames: "dropdown", overlay: dropdownOptions(), placement: "bottom-start", dropdownStyle: {
                "--font-stack-full": "".concat(theme.typography.button.fontFamily),
                "z-index": "500",
            }, loading: loading || false, positionStrategy: "absolute", trigger: "click", onVisibleChange: function (visible) {
                if (!visible) {
                    counterOverrideRef.current = null;
                    updateFilters({ rerenderKey: Math.random() });
                }
                else {
                    counterOverrideRef.current = buttonProps.counter;
                    updateFilters({ rerenderKey: undefined });
                }
            }, closeOnDropdownClick: multiple ? false : true }, (dropdownOverrideProps || {}), { children: _jsx(DropdownButton, { label: label, iconOverrideProps: iconOverrideProps, buttonProps: buttonProps, buttonOverrideProps: buttonOverrideProps }) })) })));
};
export default Dropdown;
