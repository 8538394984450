var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Box, styled } from "@mui/material";
import { Empty } from "@eightfold.ai/octuple";
var PageNotFound = function (_a) {
    var title = _a.title, description = _a.description, mode = _a.mode;
    var Container = styled(Box)({
        height: "60%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    });
    return (_jsx(Container, __assign({ "data-testid": "pageNotFound" }, { children: _jsx(Empty, { description: description, descriptionClassNames: "my-description-class", mode: mode ? mode : "search", title: title }) })));
};
export default PageNotFound;
