var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useGetLeaderFiltersQuery } from "../../store/api/core";
import { filterListeners, getFilters, updateFilters, } from "../../providers/FilterProvider";
import Dropdown from "../Dropdown/Dropdown";
import { Box } from "@mui/material";
import { useClient } from "../../hooks/client";
export var FilterResetEventName = "FilterResetEventName";
export var MultiSelectFilter = function (_a) {
    var _b;
    var _c, _d, _e, _f;
    var label = _a.label, entity = _a.entity, filterEntity = _a.filterEntity, sx = _a.sx, useOptionsQuery = _a.useOptionsQuery;
    var _g = useState(), initial = _g[0], setInitial = _g[1];
    var _h = useState(), rerenderKey = _h[0], setRerenderKey = _h[1];
    var _j = useState(true), showFilter = _j[0], setShowFilter = _j[1];
    var _k = useState(true), isFirstRender = _k[0], setIsFirstRender = _k[1];
    var client = useClient();
    var clientId;
    var filters = getFilters();
    if (filters.clientId) {
        clientId = filters.clientId;
    }
    else {
        clientId = client.clientId;
    }
    var optionsResponse = (useOptionsQuery || useGetLeaderFiltersQuery)({
        clientId: clientId,
        filters: __assign(__assign({}, filters), (_b = {}, _b[filterEntity ? filterEntity : entity] = "", _b)),
    });
    var options = [];
    if ((_d = (_c = optionsResponse === null || optionsResponse === void 0 ? void 0 : optionsResponse.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d[entity]) {
        options = (_f = (_e = optionsResponse === null || optionsResponse === void 0 ? void 0 : optionsResponse.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f[entity];
    }
    var getSelected = function () {
        var _a;
        if (((_a = getFilters()) === null || _a === void 0 ? void 0 : _a[filterEntity ? filterEntity : entity]) != null) {
            return getFilters()[filterEntity ? filterEntity : entity];
        }
    };
    var updateSelected = function () {
        setInitial(getSelected() || []);
    };
    useEffect(function () {
        window.addEventListener(FilterResetEventName, updateSelected);
        return function () {
            window.removeEventListener(FilterResetEventName, updateSelected);
        };
    }, []);
    useEffect(function () {
        var updateListener = function (_, _a) {
            var rerenderKey = _a.rerenderKey;
            if (!rerenderKey) {
                return;
            }
            updateSelected();
        };
        filterListeners.add(updateListener);
        return function () {
            filterListeners.delete(updateListener);
        };
    }, []);
    var updateFiltersLocally = function (selected) {
        var _a;
        updateFilters((_a = {}, _a[filterEntity ? filterEntity : entity] = selected, _a));
    };
    useEffect(function () {
        setInitial(getSelected() || []);
    }, [JSON.stringify(options), JSON.stringify(getSelected())]);
    // Check options only on first render
    useEffect(function () {
        if (isFirstRender && !optionsResponse.isLoading) {
            if (options.length === 0 && entity !== "assigned_box") {
                setShowFilter(false);
            }
            setIsFirstRender(false);
        }
    }, [isFirstRender, options, optionsResponse.isLoading]);
    if (!showFilter)
        return null;
    return (_jsx(Box, __assign({ component: "span", sx: sx || {} }, { children: _jsx(Dropdown, { rerenderMe: function () {
                updateSelected();
                setRerenderKey(Math.random());
            }, "data-testid": "".concat(entity, "-filter"), options: options, label: label, initial: initial == null ? getSelected() : initial, multiple: true, onDropdownVisibilityChange: function (visible) {
                !visible && updateSelected();
            }, onChangeMultiple: updateFiltersLocally, loading: optionsResponse.isLoading }, "filter-".concat(entity, "-").concat(rerenderKey)) }), "container-".concat(entity, "-").concat(rerenderKey)));
};
