// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hxPQ5gkLqEDaVuRves3q {
  color: var(--grey-color-90);
  font-family: "Avenir Medium", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 16px 24px;
  position: absolute;
  top: 16px;
  left: 0;
}

.YTKqjccNSw4RTuxlBIjQ {
  width: 100%;
  padding: 24px;
}

.Ozizr_FcxXt5qNSx4dJp {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.IeIsmWgvZjoZGmfgH7DJ {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}

.S3RAZ7yiZv70J0Y3hiu1 {
  margin-bottom: 24px;
}

.Yn1TGg5SJzK4B3sMy9pZ {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 12px;
  border-radius: 24px;
  cursor: pointer;
  white-space: nowrap;
  height: 35px;
}

.Wsmk6Ur5l1A6tA7QRlgl {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  height: 24px;
  color: #343c4c;
  font-size: 16px;
  line-height: 24px;
  font-family: "Avenir Heavy", sans-serif;
  background-color: #b0f3fe;
  border-radius: 4px;
}

.MFCDfV_rPiHDmxd12dIV {
  color: var(--grey-color-70);
  font-family: "Avenir Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Filters/Additional.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,wCAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,8BAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,uCAAA;EACA,yBAAA;EACA,kBAAA;AACF;;AAEA;EACE,2BAAA;EACA,wCAAA;EACA,eAAA;EACA,gBAAA;EACA,6BAAA;EACA,YAAA;AACF","sourcesContent":[".panelHeader {\n  color: var(--grey-color-90);\n  font-family: \"Avenir Medium\", sans-serif;\n  font-weight: 400;\n  font-size: 20px;\n  line-height: 28px;\n  padding: 16px 24px;\n  position: absolute;\n  top: 16px;\n  left: 0;\n}\n\n.body {\n  width: 100%;\n  padding: 24px;\n}\n\n.additionalFilterContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n}\n\n.additionalFilterButtonsContainer {\n  padding: 24px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.highPotentialTalentCheckBoxWrapper {\n  margin-bottom: 24px;\n}\n\n.additionalButtonContainer {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  padding: 4px 12px;\n  border-radius: 24px;\n  cursor: pointer;\n  white-space: nowrap;\n  height: 35px;\n}\n\n.additionalButtonCounter {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0 8px;\n  height: 24px;\n  color: #343c4c;\n  font-size: 16px;\n  line-height: 24px;\n  font-family: \"Avenir Heavy\", sans-serif;\n  background-color: #b0f3fe;\n  border-radius: 4px;\n}\n\n.additionalButton {\n  color: var(--grey-color-70);\n  font-family: \"Avenir Medium\", sans-serif;\n  font-size: 16px;\n  font-weight: 500;\n  background-color: transparent;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelHeader": `hxPQ5gkLqEDaVuRves3q`,
	"body": `YTKqjccNSw4RTuxlBIjQ`,
	"additionalFilterContainer": `Ozizr_FcxXt5qNSx4dJp`,
	"additionalFilterButtonsContainer": `IeIsmWgvZjoZGmfgH7DJ`,
	"highPotentialTalentCheckBoxWrapper": `S3RAZ7yiZv70J0Y3hiu1`,
	"additionalButtonContainer": `Yn1TGg5SJzK4B3sMy9pZ`,
	"additionalButtonCounter": `Wsmk6Ur5l1A6tA7QRlgl`,
	"additionalButton": `MFCDfV_rPiHDmxd12dIV`
};
export default ___CSS_LOADER_EXPORT___;
