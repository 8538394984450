var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { styled, TableHead, useTheme, Box, Pagination, Typography, } from "@mui/material";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowSelector from "./ArrowSelector";
import ArrowSelected from "./ArrowSelected";
import { Empty } from "@eightfold.ai/octuple";
import { FilterContext } from "../../providers/FilterProvider";
export var ValidSortDirections;
(function (ValidSortDirections) {
    ValidSortDirections["ASC"] = "asc";
    ValidSortDirections["DESC"] = "desc";
})(ValidSortDirections || (ValidSortDirections = {}));
var invertValidSortDirection = function (direction, defaultDirection) {
    return !direction
        ? defaultDirection || ValidSortDirections.ASC
        : direction == ValidSortDirections.ASC
            ? ValidSortDirections.DESC
            : ValidSortDirections.ASC;
};
var ShowingInfoContainer = styled(Typography)(function () { return ({
    fontFamily: "Avenir Book",
    fontSize: "15px",
    height: "41px",
    marginBottom: "8px",
}); });
var sorter = function (_a) {
    var columnIndex = _a.columnIndex, isDescending = _a.isDescending;
    return function (a, b) {
        var one = a[columnIndex] != null ? a[columnIndex] : "";
        var two = b[columnIndex] != null ? b[columnIndex] : "";
        one = Object.prototype.hasOwnProperty.call(one, "sort") ? one.sort : one;
        two = Object.prototype.hasOwnProperty.call(two, "sort") ? two.sort : two;
        if (one === two) {
            return 0;
        }
        var multiplier = isDescending ? -1 : 1;
        if (one == undefined) {
            return multiplier;
        }
        else if (two == undefined) {
            return -multiplier;
        }
        else if (one > two) {
            return -multiplier;
        }
        return multiplier;
    };
};
var getInitialDescendings = function (rawSortColumnIndexes, rawSortDirections) {
    if (rawSortColumnIndexes == null) {
        return [];
    }
    var sortColumnIndexes = Array.isArray(rawSortColumnIndexes)
        ? rawSortColumnIndexes
        : [rawSortColumnIndexes];
    var sortDirections = Array.isArray(rawSortDirections)
        ? rawSortDirections
        : [rawSortDirections];
    return sortColumnIndexes.map(function (column, index) { return ({
        columnIndex: column,
        isDescending: sortDirections[index] == ValidSortDirections.DESC,
    }); });
};
var OuterArrowContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: "0 6px",
    height: "100%",
    width: "10px",
});
var ArrowContainer = styled(Box)({
    position: "absolute",
    bottom: "14px",
});
var UpArrowContainer = styled(Box)({
    position: "absolute",
    bottom: "13px",
});
var DownArrowContainer = styled(Box)({
    position: "absolute",
    bottom: "11px",
});
var Title = styled("div")(function () { return ({
    fontSize: "20px",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "0 10px 2px",
}); });
var StyledTableCell = styled(TableCell)(function () { return ({
    fontFamily: "Avenir Book",
    border: "none",
    color: "#4F5666",
    fontSize: "14px",
    padding: "12px 8px",
}); });
var StyledTableHeaderCell = styled(StyledTableCell)(function () { return ({
    fontFamily: "Avenir Medium",
    fontWeight: "500",
    color: "#69717F",
    border: "none",
    cursor: "pointer",
    padding: "8px 10px",
    borderBottom: "1px solid white",
    position: "relative",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
}); });
var StyledHeaderCellContainer = styled("div")(function () { return ({
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
}); });
var StyledTableRow = styled(TableRow)(function () { return ({
    "th:first-child": {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    "th:last-child": {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    "td:first-child": {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    "td:last-child": {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
}); });
var TotalsTableRow = styled(TableRow)(function () { return ({
    backgroundColor: "rgba(240, 240, 240, 0.5)",
    td: {
        padding: "14px 10px",
    },
    "td:first-child": {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    "td:last-child": {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
}); });
var PaginationContainer = styled(Box)(function () { return ({
    display: "flex",
    justifyContent: "center",
    marginTop: "43px",
    ".MuiPaginationItem-root": {
        fontFamily: "Avenir Medium",
        minWidth: "32px",
        height: "32px",
        padding: "3px 6px",
        margin: "0 3px",
        color: "#002136",
        borderRadius: "4px",
        backgroundColor: "#F6F7F8",
        border: "none",
    },
    ".MuiPaginationItem-root:hover:not(.MuiPaginationItem-ellipsis):not(.Mui-selected)": {
        background: "#EBFDFF",
    },
    ".MuiPaginationItem-ellipsis": {
        fontFamily: "Avenir Heavy",
    },
    ".MuiPaginationItem-page.Mui-selected": {
        color: "#146DA6",
        backgroundColor: "#B0F3FE",
        border: "1px solid #146DA6",
    },
    ".MuiPaginationItem-root.Mui-selected:hover": {
        background: "#B0F3FE",
    },
}); });
var TableHeadStyle = styled(TableHead)(function () { return ({
    fontFamily: "Avenir",
    fontWeight: 500,
    fontSize: "14px",
    padding: "24px 16px 12px",
    gap: "4px",
    "th::after": {
        content: '""',
        backgroundColor: "#69717F",
        position: "absolute",
        left: "0",
        right: "0",
        bottom: "0",
        height: "1px",
    },
    "th:first-child::after": {
        content: '""',
        backgroundColor: "#69717F",
        position: "absolute",
        height: "1px",
    },
    "th:last-child::after": {
        content: '""',
        backgroundColor: "#69717F",
        position: "absolute",
        height: "1px",
    },
}); });
var TableBodyStyle = styled(TableBody)(function () { return ({
    background: "white",
    fontFamily: "Avenir Book",
    fontSize: "16px",
    alignItems: "center",
    padding: "0px",
    gap: "8px",
    "& .MuiTableRow-root.MuiTableRow-hover:hover td": {
        backgroundColor: "#EBFDFF !important",
    },
    "& .MuiTableRow-root.MuiTableRow-hover:hover td.highlighted-row": {
        backgroundColor: "#E5F6F8 !important",
    },
}); });
var HeaderContainer = styled(Box)(function () { return ({}); });
var Table = function (props) {
    var _a, _b, _c, _d, _e;
    var rows = props.data ? __spreadArray([], props.data, true) : null;
    var _f = React.useState(getInitialDescendings(props.defaultSortColumnIndex, props.defaultSortDirection)), descendings = _f[0], setDescendings = _f[1];
    var _g = React.useState(1), currentPage = _g[0], setCurrentPage = _g[1];
    var formatters = props.headers.map(function (header, index) {
        return (props.formatters || [])[index]
            ? (props.formatters || [])[index]
            : function (echo) { return echo; };
    });
    var isColumnDescending = function (column) {
        var is = descendings.find(function (_a) {
            var columnIndex = _a.columnIndex, isDescending = _a.isDescending;
            return columnIndex == column && isDescending;
        });
        var isNot = descendings.find(function (_a) {
            var columnIndex = _a.columnIndex, isDescending = _a.isDescending;
            return columnIndex == column && isDescending == false;
        });
        return is ? true : isNot ? false : undefined;
    };
    var isLastColumnDescending = function (column) {
        if (descendings.length <= 0 ||
            column != descendings[descendings.length - 1].columnIndex) {
            return undefined;
        }
        return isColumnDescending(column);
    };
    var sortRows = function (_a) {
        var columnIndex = _a.columnIndex, isDescending = _a.isDescending;
        return function () {
            if ((props.unsortableColumns || []).includes(columnIndex)) {
                return;
            }
            setDescendings(function (directions) { return __spreadArray(__spreadArray([], directions.filter(function (command) { return columnIndex != command.columnIndex; }), true), [
                { columnIndex: columnIndex, isDescending: isDescending },
            ], false); });
        };
    };
    descendings.forEach(function (sortCommand) {
        rows === null || rows === void 0 ? void 0 : rows.sort(sorter(sortCommand));
    });
    var rowsPerPage = props.rowsPerPage || 10;
    var numberOfPages = Math.ceil(((rows === null || rows === void 0 ? void 0 : rows.length) || 0) / rowsPerPage);
    var paginationThreshold = props.paginationThreshold || 10;
    var paginationEnabled = paginationThreshold > 0 && paginationThreshold <= ((rows === null || rows === void 0 ? void 0 : rows.length) || 0);
    function handleChangePage(event, newPage) {
        setCurrentPage(newPage);
    }
    var showingInfo = props.showingInfo &&
        props.showingInfo({
            total: ((_a = props.data) === null || _a === void 0 ? void 0 : _a.length) || 0,
            count: numberOfPages != currentPage && (((_b = props.data) === null || _b === void 0 ? void 0 : _b.length) || 0) != 0
                ? rowsPerPage
                : (((_c = props.data) === null || _c === void 0 ? void 0 : _c.length) || 0) % rowsPerPage == 0 &&
                    (((_d = props.data) === null || _d === void 0 ? void 0 : _d.length) || 0) != 0
                    ? rowsPerPage
                    : (((_e = props.data) === null || _e === void 0 ? void 0 : _e.length) || 0) % rowsPerPage,
        });
    var columnSortStates = Array.from(new Array(props.headers.length)).map(function (_, index) {
        if ((props.unsortableColumns || []).includes(index)) {
            return ColumnSortState.None;
        }
        var isDescending = isLastColumnDescending(index);
        return isDescending == undefined
            ? ColumnSortState.Both
            : isDescending
                ? ColumnSortState.Down
                : ColumnSortState.Up;
    });
    var sortDumbRows = function (columnIndex) {
        return sortRows({
            columnIndex: columnIndex,
            isDescending: isColumnDescending(columnIndex) == undefined
                ? true
                : !isColumnDescending(columnIndex),
        });
    };
    if (numberOfPages < currentPage && numberOfPages != 0) {
        setCurrentPage(numberOfPages);
    }
    return (_jsx(_Fragment, { children: _jsx(DumbTable, { headers: props.headers, replaceEmpty: props.replaceEmpty, showingInfo: showingInfo, title: props.title, columnSortStates: columnSortStates, totalsRow: props.totalsRow, highlightColumnIndex: props.highlightColumnIndex, rowSx: props.rowSx, data: rows === null || rows === void 0 ? void 0 : rows.slice((currentPage - 1) * rowsPerPage, !paginationEnabled
                ? (rows === null || rows === void 0 ? void 0 : rows.length) || 0
                : (currentPage - 1) * rowsPerPage + rowsPerPage), sortRows: sortDumbRows, formatters: formatters, pagination: paginationEnabled
                ? {
                    pageCount: numberOfPages,
                    currentPage: currentPage,
                    onChange: handleChangePage,
                }
                : undefined }) }));
};
var ColumnSortState;
(function (ColumnSortState) {
    ColumnSortState[ColumnSortState["Up"] = 0] = "Up";
    ColumnSortState[ColumnSortState["Down"] = 1] = "Down";
    ColumnSortState[ColumnSortState["Both"] = 2] = "Both";
    ColumnSortState[ColumnSortState["None"] = 3] = "None";
})(ColumnSortState || (ColumnSortState = {}));
export function EndpointPaginatingTable(_a) {
    var useData = _a.useData, defaultSortKeyOrder = _a.defaultSortKeyOrder, title = _a.title, columnDescriptions = _a.columnDescriptions, replaceEmpty = _a.replaceEmpty, showingInfo = _a.showingInfo, rowSx = _a.rowSx;
    var _b = expandColumnDescriptions(columnDescriptions), headers = _b.headers, formatters = _b.formatters, defaultSortDirections = _b.defaultSortDirections, totalsRow = _b.totalsRow, unsortableColumns = _b.unsortableColumns, highlightColumnIndex = _b.highlightColumnIndex;
    var endpointKeyColumnMap = columnDescriptions.reduce(function (current, _a, index) {
        var _b;
        var endpointKey = _a.endpointKey;
        return (__assign(__assign({}, current), (endpointKey
            ? (_b = {},
                _b[endpointKey] = index,
                _b) : {})));
    }, {});
    var _c = React.useState(0), page = _c[0], setPage = _c[1];
    var getFilters = React.useContext(FilterContext).getFilters;
    React.useEffect(function () {
        setPage(0);
    }, [JSON.stringify(getFilters())]);
    var _d = React.useState((defaultSortKeyOrder || []).map(function (value) { return ({
        column: endpointKeyColumnMap[value],
        direction: defaultSortDirections[endpointKeyColumnMap[value]],
    }); })), sortColumnIndexes = _d[0], setSortColumnIndexes = _d[1];
    var columnSortStates = columnDescriptions.map(function (_, index) {
        var _a, _b;
        if (unsortableColumns.includes(index)) {
            return ColumnSortState.None;
        }
        if (!sortColumnIndexes) {
            return ColumnSortState.Both;
        }
        if (((_a = sortColumnIndexes.at(0)) === null || _a === void 0 ? void 0 : _a.column) == index) {
            return ((_b = sortColumnIndexes.at(0)) === null || _b === void 0 ? void 0 : _b.direction) == ValidSortDirections.ASC
                ? ColumnSortState.Down
                : ColumnSortState.Up;
        }
        return ColumnSortState.Both;
    });
    var sort = sortColumnIndexes.map(function (_a) {
        var column = _a.column, direction = _a.direction;
        return "".concat(direction == ValidSortDirections.ASC ? "" : "-").concat(columnDescriptions[column].endpointKey);
    });
    var _e = useData({ page: page, sort: sort }), data = _e.data, paginationStatus = _e.paginationStatus;
    var tableData = data === null || data === void 0 ? void 0 : data.map(function (row) {
        return columnDescriptions.map(function (_a) {
            var valueTransformer = _a.valueTransformer;
            return valueTransformer(row);
        });
    });
    var sortRows = function (index, direction) { return function () {
        var previous = sortColumnIndexes.find(function (_a) {
            var column = _a.column;
            return column == index;
        });
        var toSet = __spreadArray([
            {
                column: index,
                direction: direction
                    ? direction
                    : invertValidSortDirection(previous === null || previous === void 0 ? void 0 : previous.direction, columnDescriptions[index].defaultSortDirection),
            }
        ], sortColumnIndexes.filter(function (_a) {
            var column = _a.column;
            return column != index;
        }), true);
        setSortColumnIndexes(toSet);
    }; };
    columnDescriptions.forEach(function (_a) {
        var endpointKey = _a.endpointKey, sortOn = _a.sortOn, defaultSortDirection = _a.defaultSortDirection;
        if (sortOn && endpointKey) {
            sortOn.current = sortRows(endpointKeyColumnMap[endpointKey], defaultSortDirection || ValidSortDirections.ASC);
        }
    });
    var dumbShowingInfo = showingInfo &&
        (paginationStatus === null || paginationStatus === void 0 ? void 0 : paginationStatus.totalCount) != null &&
        (paginationStatus === null || paginationStatus === void 0 ? void 0 : paginationStatus.limit) != null
        ? showingInfo({
            count: (paginationStatus === null || paginationStatus === void 0 ? void 0 : paginationStatus.pageCount) == page + 1
                ? tableData === null || tableData === void 0 ? void 0 : tableData.length
                : paginationStatus === null || paginationStatus === void 0 ? void 0 : paginationStatus.limit,
            total: paginationStatus === null || paginationStatus === void 0 ? void 0 : paginationStatus.totalCount,
        })
        : undefined;
    return (_jsx(DumbTable, { title: title, headers: headers, formatters: formatters, replaceEmpty: replaceEmpty, showingInfo: dumbShowingInfo, totalsRow: (totalsRow === null || totalsRow === void 0 ? void 0 : totalsRow.length) > 0 ? totalsRow : undefined, highlightColumnIndex: highlightColumnIndex, rowSx: rowSx, data: tableData, sortRows: sortRows, columnSortStates: columnSortStates, pagination: {
            pageCount: (paginationStatus === null || paginationStatus === void 0 ? void 0 : paginationStatus.pageCount) || 0,
            currentPage: page + 1,
            onChange: function (_, changeTo) { return setPage(changeTo - 1); },
        } }));
}
var DumbTable = function (_a) {
    var headers = _a.headers, replaceEmpty = _a.replaceEmpty, title = _a.title, columnSortStates = _a.columnSortStates, totalsRow = _a.totalsRow, showingInfo = _a.showingInfo, highlightColumnIndex = _a.highlightColumnIndex, rowSx = _a.rowSx, data = _a.data, pagination = _a.pagination, sortRows = _a.sortRows, formatters = _a.formatters;
    var theme = useTheme();
    var showingInfoContainer = showingInfo && typeof showingInfo == "string" ? (_jsx(ShowingInfoContainer, { children: showingInfo })) : (showingInfo);
    return (_jsxs(_Fragment, { children: [showingInfoContainer, title && _jsx(Title, { children: title }), _jsx(TableContainer, __assign({ "data-testid": "TableComponent", sx: {
                    borderRadius: "2px",
                    elevation: "0",
                    boxShadow: "none",
                    overflowX: "visible",
                }, component: Paper }, { children: _jsxs(MUITable, __assign({ sx: {
                        minWidth: "100%",
                    } }, { children: [_jsx(TableHeadStyle, { children: _jsx(StyledTableRow, { children: headers.map(function (header, index) {
                                    var _a;
                                    return (_jsxs(StyledTableHeaderCell, __assign({ "data-testid": "header-".concat(index), onClick: sortRows(index), style: {
                                            verticalAlign: "bottom",
                                            mixBlendMode: "multiply",
                                            background: highlightColumnIndex === index
                                                ? theme.palette.grey.A200
                                                : "",
                                        } }, { children: [" ", _jsxs(StyledHeaderCellContainer, { children: [_jsx(HeaderContainer, { children: header }), (_a = {},
                                                        _a[ColumnSortState.None] = null,
                                                        _a[ColumnSortState.Both] = (_jsx(OuterArrowContainer, { children: _jsx(ArrowContainer, __assign({ "data-testid": "bothArrow-".concat(index) }, { children: _jsx(ArrowSelector, {}) })) })),
                                                        _a[ColumnSortState.Down] = (_jsx(OuterArrowContainer, { children: _jsx(DownArrowContainer, __assign({ "data-testid": "downArrow-".concat(index) }, { children: _jsx(ArrowSelected, { isUp: false }) })) })),
                                                        _a[ColumnSortState.Up] = (_jsx(OuterArrowContainer, { children: _jsx(UpArrowContainer, __assign({ "data-testid": "upArrow-".concat(index) }, { children: _jsx(ArrowSelected, { isUp: true }) })) })),
                                                        _a)[columnSortStates[index]]] })] }), "header-".concat(index)));
                                }) }) }), _jsxs(TableBodyStyle, { children: [totalsRow && (_jsx(TotalsTableRow, __assign({ "data-testid": "row-".concat(0) }, { children: totalsRow.map(function (column, columnIndex) { return (_jsx(StyledTableCell, __assign({ "data-testid": "cell-0-".concat(columnIndex), style: {
                                            mixBlendMode: "multiply",
                                            background: highlightColumnIndex === columnIndex
                                                ? theme.palette.grey.A200
                                                : "",
                                        } }, { children: column }), "cell-0-".concat(columnIndex))); }) }), 0)), data === null || data === void 0 ? void 0 : data.map(function (row, rowIndex) { return (_jsx(StyledTableRow, __assign({ "data-testid": "row-".concat(rowIndex + 1), hover: true, sx: __assign({}, (rowSx || {})) }, { children: row.map(function (cell, columnIndex) {
                                        var _a;
                                        return (_jsx(StyledTableCell, __assign({ "data-testid": "cell-".concat(rowIndex + 1, "-").concat(columnIndex), className: highlightColumnIndex === columnIndex
                                                ? "highlighted-row"
                                                : "", style: __assign({}, (highlightColumnIndex === columnIndex
                                                ? {
                                                    background: theme.palette.grey.A200,
                                                }
                                                : {})) }, { children: _jsx(_Fragment, { children: (_a = formatters[columnIndex]) === null || _a === void 0 ? void 0 : _a.call(formatters, replaceEmpty && (cell === "" || cell == null)
                                                    ? replaceEmpty
                                                    : cell) }) }), "cell-".concat(rowIndex + 1, "-").concat(columnIndex)));
                                    }) }), rowIndex + 1)); })] })] })) })), data && (data === null || data === void 0 ? void 0 : data.length) == 0 && (_jsx(Empty, { descriptionClassNames: "my-description-class", mode: "data", style: {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    marginTop: "20px",
                }, title: "No data found" })), pagination && (_jsx(PaginationContainer, { children: _jsx(Pagination, { count: pagination.pageCount, variant: "outlined", shape: "rounded", page: pagination.currentPage, onChange: pagination.onChange }) }))] }));
};
var expandColumnDescriptions = function (columnDescriptions) {
    var headers = [];
    var formatters = [];
    var defaultSortDirections = [];
    var totalsRow = [];
    var unsortableColumns = [];
    var defaultSortIndexes = {};
    var highlightColumnIndex;
    columnDescriptions.forEach(function (_a, index) {
        var header = _a.header, formatter = _a.formatter, defaultSortOrderKey = _a.defaultSortOrderKey, defaultSortDirection = _a.defaultSortDirection, totalCell = _a.totalCell, isUnsortable = _a.isUnsortable, isHighlighted = _a.isHighlighted;
        headers[index] = header || "";
        formatters[index] = formatter;
        if (defaultSortOrderKey) {
            defaultSortIndexes[defaultSortOrderKey] = index;
        }
        if (isHighlighted) {
            highlightColumnIndex = index;
        }
        defaultSortDirections[index] =
            defaultSortDirection || ValidSortDirections.ASC;
        if (totalCell) {
            totalsRow[index] = totalCell;
        }
        if (isUnsortable) {
            unsortableColumns.push(index);
        }
    });
    return {
        headers: headers,
        formatters: formatters,
        defaultSortDirections: defaultSortDirections,
        totalsRow: totalsRow,
        unsortableColumns: unsortableColumns,
        defaultSortIndexes: defaultSortIndexes,
        highlightColumnIndex: highlightColumnIndex,
    };
};
export var ColumnDescribedTable = function (_a) {
    var defaultSortKeyOrder = _a.defaultSortKeyOrder, columnDescriptions = _a.columnDescriptions, title = _a.title, replaceEmpty = _a.replaceEmpty, data = _a.data, rowSx = _a.rowSx, showingInfo = _a.showingInfo;
    var _b = expandColumnDescriptions(columnDescriptions), headers = _b.headers, formatters = _b.formatters, defaultSortDirections = _b.defaultSortDirections, totalsRow = _b.totalsRow, unsortableColumns = _b.unsortableColumns, defaultSortIndexes = _b.defaultSortIndexes, highlightColumnIndex = _b.highlightColumnIndex;
    var defaultSortColumnIndex = defaultSortKeyOrder === null || defaultSortKeyOrder === void 0 ? void 0 : defaultSortKeyOrder.reduce(function (result, next) {
        return defaultSortIndexes[next.column] != undefined
            ? __spreadArray(__spreadArray([], result, true), [defaultSortIndexes[next.column]], false) : result;
    }, []);
    defaultSortKeyOrder === null || defaultSortKeyOrder === void 0 ? void 0 : defaultSortKeyOrder.forEach(function (_a) {
        var column = _a.column, direction = _a.direction;
        defaultSortDirections[defaultSortIndexes[column]] = direction;
    });
    var tableData = data.map(function (row) {
        return columnDescriptions.map(function (_a) {
            var valueTransformer = _a.valueTransformer;
            return valueTransformer(row);
        });
    });
    return (_jsx(Table, { headers: headers, formatters: formatters, defaultSortColumnIndex: defaultSortColumnIndex, defaultSortDirection: defaultSortDirections, highlightColumnIndex: highlightColumnIndex, totalsRow: totalsRow.length > 0 ? totalsRow : undefined, paginationThreshold: 10, unsortableColumns: unsortableColumns, title: title, replaceEmpty: replaceEmpty, data: tableData, rowSx: rowSx, showingInfo: showingInfo }));
};
export default Table;
