var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import BackToButton from "../../components/BackToButton";
import SecondaryMainHeading from "../../components/SecondMainHeading/SecondMainHeading";
import { SkeletonGrid, mergedNames, EditIconedLabel, } from "../../components/NineBox/NineBox";
import { useParams } from "react-router-dom";
import { useGetNineBox, updateNineBoxSettingsQuery, NineBoxSwitchAxisQuery, addNineBoxAuditLogQuery, } from "../../store/api/core";
import { NineBoxAuditLogUpdateType } from "../../store/api/types/core";
import { getRoute } from "../../routes/getAllRoutes";
import styles from "./NineBoxSettings.module.scss";
import { DisappearingInfoBar } from "../InfoBar/InfoBar";
import { Icon, IconName, IconSize, Form, ConfigProvider, TextInput, TextArea, TextInputWidth, } from "@eightfold.ai/octuple";
import Button from "../Button";
import FormRequired from "../FormRequired";
import Dialog from "../Dialog";
import SpinnerAndErrorer from "../SpinnerAndErrorer";
import RevertIcon from "../RevertIcon";
import { getEmptyTalentReviewLabelBoxEdit, GlobalStateContext, } from "../../providers/GlobalStateProvider";
import { useNavigate } from "../../hooks/navigate";
import { IconPathName } from "../../IconPath/mdi";
import Tooltip, { TooltipPlacement } from "../Tooltip/Tooltip";
import { IconButton, ButtonVariant as IntelligenceButtonVariant, } from "../Button/Button";
var TalentReviewNineBoxSetting = function () {
    var _a, _b, _c;
    var navigate = useNavigate();
    var id = useParams().id;
    var nineBoxDetails = useGetNineBox({ id: id });
    var boxName = (((_a = nineBoxDetails === null || nineBoxDetails === void 0 ? void 0 : nineBoxDetails.data) === null || _a === void 0 ? void 0 : _a.data) || {}).boxName;
    var _d = useState(false), showLabelForm = _d[0], setShowLabelForm = _d[1];
    var _e = useState(null), openBox = _e[0], setOpenBox = _e[1];
    var _f = useContext(GlobalStateContext), getTalentReviewLabelEdits = _f.getTalentReviewLabelEdits, clearTalentReviewLabelEdits = _f.clearTalentReviewLabelEdits, setTalentReviewLabelEdits = _f.setTalentReviewLabelEdits;
    var edits = getTalentReviewLabelEdits();
    var nineBox = useGetNineBox({ id: id });
    var _g = useState([]), banners = _g[0], setBanners = _g[1];
    var _h = useContext(GlobalStateContext), setHoldUrl = _h.setHoldUrl, getHoldUrl = _h.getHoldUrl;
    var successCounter = useRef();
    var updateNineBoxlabels = updateNineBoxSettingsQuery({
        substitutions: { id: id },
        invalidates: [{ queryKey: ["{ninebox}/nine-box/".concat(id)] }],
    });
    var addNineBoxAuditLog = addNineBoxAuditLogQuery({
        substitutions: { id: id },
        invalidates: [{ queryKey: ["{ninebox}/nine-box?clientId={ClientId}"] }],
    });
    var switchNineBoxAxis = NineBoxSwitchAxisQuery({
        substitutions: { id: id },
        invalidates: [{ queryKey: ["{ninebox}/nine-box/".concat(id)] }],
    });
    var isEdited = !!([edits.xAxis, edits.yAxis].some(function (_a) {
        var axisLabel = _a.axisLabel, lowLabel = _a.lowLabel, middleLabel = _a.middleLabel, highLabel = _a.highLabel, lowDescription = _a.lowDescription, middleDescription = _a.middleDescription, highDescription = _a.highDescription;
        return axisLabel ||
            lowLabel ||
            middleLabel ||
            highLabel ||
            lowDescription ||
            middleDescription ||
            highDescription;
    }) || edits.boxes.some(function (_a) {
        var name = _a.name, description = _a.description;
        return name || description;
    }));
    var holdUrl = location === null || location === void 0 ? void 0 : location.pathname;
    if (isEdited && ((_b = getHoldUrl()) === null || _b === void 0 ? void 0 : _b.onUrl) != (location === null || location === void 0 ? void 0 : location.pathname)) {
        setHoldUrl({
            onUrl: location === null || location === void 0 ? void 0 : location.pathname,
            urls: [new RegExp(holdUrl.replace("/", "\\/"))],
            dialog: (_jsx(Dialog, { header: "Settings have not been saved", body: "Unsaved changes will be lost if you exit before saving.", visible: true, buttons: {
                    okButtonProps: {
                        text: "Save and exit",
                        onClick: function () {
                            saveLabels();
                            clearTalentReviewLabelEdits();
                            setHoldUrl(undefined);
                        },
                    },
                    cancelButtonProps: {
                        text: "Exit without saving",
                        onClick: function () {
                            clearTalentReviewLabelEdits();
                            setHoldUrl(undefined);
                        },
                    },
                }, onClose: function () {
                    navigate(holdUrl);
                } })),
        });
    }
    else if (!isEdited) {
        setHoldUrl(undefined);
    }
    useEffect(function () {
        var onBeforeUnload = function (e) {
            if (isEdited) {
                e.preventDefault();
                e.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", onBeforeUnload);
        return function () {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [isEdited]);
    if (!(nineBox === null || nineBox === void 0 ? void 0 : nineBox.data)) {
        return _jsx("div", {});
    }
    var _j = ((_c = nineBox === null || nineBox === void 0 ? void 0 : nineBox.data) === null || _c === void 0 ? void 0 : _c.data) || {}, xAxis = _j.xAxis, yAxis = _j.yAxis, boxes = _j.boxes;
    var boxIndexToKey = function (index) {
        return "box".concat(["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"][index]);
    };
    var saveLabels = function () {
        var successMsg = "All settings have been successfully updated";
        var boxesToUpdate = edits.boxes.reduce(function (acc, _a, index) {
            var boxDescription = _a.boxDescription, name = _a.name;
            if (boxes[index].name == name &&
                boxes[index].boxDescription == boxDescription) {
                return acc;
            }
            if (!name || !boxDescription) {
                return acc;
            }
            acc[boxIndexToKey(index)] = {
                name: name,
                boxDescription: boxDescription,
            };
            return acc;
        }, {});
        if (isEdited || Object.keys(boxesToUpdate).length > 0) {
            var _a = [
                ["lowLabel", "lowLabel"],
                ["midLabel", "middleLabel"],
                ["highLabel", "highLabel"],
            ].reduce(function (_a, _b) {
                var yLabels = _a.yLabels, xLabels = _a.xLabels;
                var endpoint = _b[0], local = _b[1];
                if (edits.yAxis[local]) {
                    yLabels[endpoint] = edits.yAxis[local];
                }
                if (edits.xAxis[local]) {
                    xLabels[endpoint] = edits.xAxis[local];
                }
                return { xLabels: xLabels, yLabels: yLabels };
            }, { xLabels: {}, yLabels: {} }), xLabels = _a.xLabels, yLabels = _a.yLabels;
            var onSuccess = function () {
                if (successCounter.current === 0) {
                    successCounter.current = 1;
                    return;
                }
                clearTalentReviewLabelEdits();
                setBanners(function (b) {
                    return b.concat(_jsx(DisappearingInfoBar, { content: successMsg, type: "positive" }));
                });
            };
            successCounter.current = 0;
            addNineBoxAuditLog.mutate({
                updateType: NineBoxAuditLogUpdateType.ChangeLabels,
            }, {
                onSuccess: onSuccess,
            });
            updateNineBoxlabels.mutate(__assign({ xAxis: xLabels, yAxis: yLabels }, boxesToUpdate), {
                onSuccess: onSuccess,
            });
        }
        else {
            setBanners(function (b) {
                return b.concat(_jsx(DisappearingInfoBar, { content: successMsg, type: "positive" }));
            });
        }
    };
    var LabelEditFormDialog = function (props) {
        var form = Form.useForm()[0];
        var initialValues = {
            xAxisLowLabel: edits.xAxis.lowLabel || xAxis.lowLabel,
            xAxisMiddleLabel: edits.xAxis.middleLabel || xAxis.middleLabel,
            xAxisHighLabel: edits.xAxis.highLabel || xAxis.highLabel,
            yAxisLowLabel: edits.yAxis.lowLabel || yAxis.lowLabel,
            yAxisMiddleLabel: edits.yAxis.middleLabel || yAxis.middleLabel,
            yAxisHighLabel: edits.yAxis.highLabel || yAxis.highLabel,
        };
        var onFormFinish = function (formValues) {
            var copy = __assign({}, edits);
            ["xAxis", "yAxis"].forEach(function (axis) {
                ["highLabel", "middleLabel", "lowLabel"].forEach(function (position) {
                    var value = formValues["".concat(axis).concat(position.charAt(0).toUpperCase() + position.slice(1))];
                    if (nineBox.data.data[axis][position] == value) {
                        copy[axis][position] = "";
                        return;
                    }
                    copy[axis][position] = value;
                });
            });
            setTalentReviewLabelEdits(copy);
        };
        var rules = [
            { required: true, message: "Label is required" },
            { type: "string", max: 20, message: "Please limit to 20 characters" },
        ];
        var LabelEditForm = function () {
            return (_jsxs(_Fragment, { children: [_jsx(FormRequired, {}), _jsx("div", __assign({ className: styles.labelEditFormSectionTitle }, { children: yAxis.axisLabel })), _jsxs(Form, __assign({ form: form, layout: "vertical", autoComplete: "off", initialValues: initialValues, onFinish: onFormFinish, "data-testid": "label-edit-form" }, { children: [_jsx(Form.Item, __assign({ label: "Label for \"".concat(yAxis.highLabel, "\""), name: "yAxisHighLabel", rules: rules, classNames: styles.labelEditFormInputWrapper }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill }) })), _jsx(Form.Item, __assign({ label: "Label for \"".concat(yAxis.middleLabel, "\""), name: "yAxisMiddleLabel", rules: rules, classNames: styles.labelEditFormInputWrapper }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill }) })), _jsx(Form.Item, __assign({ label: "Label for \"".concat(yAxis.lowLabel, "\""), name: "yAxisLowLabel", rules: rules, classNames: styles.labelEditFormInputWrapper }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill }) })), _jsx("div", __assign({ className: styles.labelEditFormSectionTitleBottom }, { children: xAxis.axisLabel })), _jsx(Form.Item, __assign({ label: "Label for \"".concat(xAxis.highLabel, "\""), name: "xAxisHighLabel", rules: rules, classNames: styles.labelEditFormInputWrapper }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill }) })), _jsx(Form.Item, __assign({ label: "Label for \"".concat(xAxis.middleLabel, "\""), name: "xAxisMiddleLabel", rules: rules, classNames: styles.labelEditFormInputWrapper }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill }) })), _jsx(Form.Item, __assign({ label: "Label for \"".concat(xAxis.lowLabel, "\""), name: "xAxisLowLabel", rules: rules, classNames: styles.labelEditFormInputWrapper }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill }) }))] }))] }));
        };
        return (_jsx(Dialog, { header: "Edit Axis Labels", body: _jsx(LabelEditForm, {}), visible: true, buttons: {
                okButtonProps: {
                    text: "Done",
                    onClick: function () {
                        form.submit();
                        setShowLabelForm(false);
                    },
                },
                cancelButtonProps: {
                    text: "Cancel",
                    onClick: function () {
                        setShowLabelForm(false);
                    },
                },
            }, onClose: props.onClose }));
    };
    var BoxNameEditDialog = function (_a) {
        var index = _a.index;
        var form = Form.useForm()[0];
        var boxData = {
            name: edits.boxes[index].name || boxes[index].name,
            boxDescription: edits.boxes[index].boxDescription || boxes[index].boxDescription,
        };
        var initialValues = {
            name: boxData.name,
            description: boxData.boxDescription,
        };
        var onFormFinish = function (index) {
            return function (_a) {
                var name = _a.name, description = _a.description;
                if (boxes[index].name == name &&
                    boxes[index].boxDescription == description) {
                    setTalentReviewLabelEdits(function (state) {
                        var copy = __assign({}, state);
                        copy.boxes[index] = getEmptyTalentReviewLabelBoxEdit();
                        return copy;
                    });
                    return;
                }
                setTalentReviewLabelEdits(function (state) {
                    var copy = __assign({}, state);
                    copy.boxes[index] = {
                        name: name,
                        boxDescription: description,
                    };
                    return copy;
                });
            };
        };
        var LabelEditForm = function () {
            return (_jsxs(_Fragment, { children: [_jsx(FormRequired, {}), _jsxs(Form, __assign({ form: form, layout: "vertical", autoComplete: "off", initialValues: initialValues, onFinish: onFormFinish(index), "data-testid": "category-edit-form" }, { children: [_jsx(Form.Item, __assign({ label: "Name", name: "name", rules: [{ required: true, message: "${label} is required" }], classNames: styles.labelEditFormInputWrapper }, { children: _jsx(TextInput, { inputWidth: TextInputWidth.fill }) })), _jsx(Form.Item, __assign({ label: "Description", name: "description", classNames: styles.labelEditFormInputWrapper }, { children: _jsx(TextArea, { inputWidth: TextInputWidth.fill, textAreaRows: 2 }) }))] }))] }));
        };
        return (_jsx(Dialog, { header: "Edit box", body: _jsx(LabelEditForm, {}), visible: true, buttons: {
                okButtonProps: {
                    text: "Done",
                    onClick: function () {
                        form.submit();
                        setOpenBox(null);
                    },
                },
                cancelButtonProps: {
                    text: "Cancel",
                    onClick: function () {
                        setOpenBox(null);
                    },
                },
            }, onClose: function () {
                setOpenBox(null);
            } }));
    };
    var Box = function (_a) {
        var index = _a.index;
        var isEdited = edits.boxes[index].name || edits.boxes[index].boxDescription;
        return (_jsxs("div", __assign({ className: "".concat(styles.boxContainer, " ").concat(mergedNames(index, "box")) }, { children: [_jsxs("div", __assign({ className: "".concat(styles.boxLabelContainer) }, { children: [_jsxs("div", __assign({ className: styles.boxLabel }, { children: [" ", edits.boxes[index].name || boxes[index].name, " "] })), _jsx("div", __assign({ className: styles.boxPencilIcon, onClick: function () {
                                setOpenBox(index);
                            }, "data-testid": "edit-category-button-".concat(index) }, { children: _jsx(Icon, { path: IconName.mdiPencil, size: IconSize.Medium }) }))] })), _jsx("div", { children: isEdited && (_jsxs("div", __assign({ className: styles.boxEditIcon, onClick: revertLabel("boxes", index, {
                            name: "",
                            boxDescription: "",
                        }) }, { children: [_jsx(RevertIcon, {}), "Revert"] }))) })] })));
    };
    var revertLabel = function (entity, field, empty) {
        return function () {
            setTalentReviewLabelEdits(function (original) {
                var copy = __assign({}, original);
                original[entity][field] = empty;
                return copy;
            });
        };
    };
    var xSkeletonedGrid = __assign(__assign({}, xAxis), { lowLabel: (_jsx(EditIconedLabel, { originalText: xAxis.lowLabel, editedText: edits.xAxis.lowLabel, revertCallback: revertLabel("xAxis", "lowLabel", "") })), middleLabel: (_jsx(EditIconedLabel, { originalText: xAxis.middleLabel, editedText: edits.xAxis.middleLabel, revertCallback: revertLabel("xAxis", "middleLabel", "") })), highLabel: (_jsx(EditIconedLabel, { originalText: xAxis.highLabel, editedText: edits.xAxis.highLabel, revertCallback: revertLabel("xAxis", "highLabel", "") })) });
    var ySkeletonedGrid = __assign(__assign({}, yAxis), { lowLabel: (_jsx(EditIconedLabel, { originalText: yAxis.lowLabel, editedText: edits.yAxis.lowLabel, revertCallback: revertLabel("yAxis", "lowLabel", "") })), middleLabel: (_jsx(EditIconedLabel, { originalText: yAxis.middleLabel, editedText: edits.yAxis.middleLabel, revertCallback: revertLabel("yAxis", "middleLabel", "") })), highLabel: (_jsx(EditIconedLabel, { originalText: yAxis.highLabel, editedText: edits.yAxis.highLabel, revertCallback: revertLabel("yAxis", "highLabel", "") })) });
    var handleSwapAxis = function () {
        switchNineBoxAxis.mutate({});
    };
    return (_jsxs(_Fragment, { children: [_jsx(SecondaryMainHeading, { backToButton: _jsx(BackToButton, { title: "Talent Review", url: getRoute("TalentReviewNineBox").replace(":id", id) }), role: "All", level: "All", "data-testid": "SecondaryHeading", secondaryHeadingTitle: "".concat(boxName, " Settings") }), _jsx("div", __assign({ className: styles.infoBarWrapper }, { children: banners })), _jsxs("div", __assign({ className: styles.gridContainer }, { children: [_jsxs("div", __assign({ className: styles.gridHeader }, { children: [_jsxs("div", { children: [_jsxs("div", __assign({ className: styles.gridHeaderLabel }, { children: [_jsx("div", __assign({ className: styles.gridHeaderLabelText }, { children: "9 Box Labels" })), _jsx("div", __assign({ className: styles.gridHeaderLabelPencil, onClick: function () {
                                                    setShowLabelForm(true);
                                                }, "data-testid": "edit-label-button" }, { children: _jsx(Icon, { path: IconName.mdiPencil, size: IconSize.Large }) }))] })), _jsx("div", __assign({ className: styles.gridHeaderSubLabel }, { children: "Change the labels as needed to match your organization's structure." }))] }), _jsxs("div", __assign({ className: styles.gridSaveWrapper }, { children: [isEdited && (_jsxs("div", __assign({ onClick: clearTalentReviewLabelEdits, className: styles.revertAllWrapper }, { children: [_jsx(RevertIcon, {}), " Revert All", " "] }))), _jsx(Button, { text: "Save", onClick: saveLabels, loading: updateNineBoxlabels.isPending })] })), openBox != null && _jsx(BoxNameEditDialog, { index: openBox }), showLabelForm &&
                                createPortal(_jsx(ConfigProvider, { children: _jsx(LabelEditFormDialog, { onClose: function () { return setShowLabelForm(false); } }) }), document.body)] })), _jsxs(SpinnerAndErrorer, __assign({ conditions: [nineBox] }, { children: [_jsx(SkeletonGrid, { xAxis: xSkeletonedGrid, yAxis: ySkeletonedGrid, boxes: boxes.map(function (boxData, index) {
                                    return (_jsx(Box, { index: index, revertCallback: revertLabel("boxes", index, {
                                            name: "",
                                            boxDescription: "",
                                        }) }, "box".concat(index)));
                                }) }), _jsx("div", __assign({ className: styles.iconWrapper }, { children: isEdited ? (_jsx(Tooltip, __assign({ placement: TooltipPlacement.Right, content: "Please save draft changes before switching the axis" }, { children: _jsx(IconButton, { testId: "switch-axis-button", iconPath: IconPathName.mdiSwap, variant: IntelligenceButtonVariant.Neutral, disabled: isEdited ? true : false }) }))) : (_jsx(IconButton, { testId: "switch-axis-button", onClick: handleSwapAxis, iconPath: IconPathName.mdiSwap, variant: IntelligenceButtonVariant.Neutral })) }))] }))] }))] }));
};
export default TalentReviewNineBoxSetting;
