var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext } from "react";
import LoadError from "../LoadError/LoadError";
import { HDSpinner } from "../../hd-ui-kit";
import { Box, styled } from "@mui/material";
import { SpinnerContext } from "../../providers/SpinnerProvider";
var willSpin = function (conditions) {
    return ((conditions === null || conditions === void 0 ? void 0 : conditions.spin) ||
        (function () {
            return conditions === null || conditions === void 0 ? void 0 : conditions.find(function (condition) { return condition.isFetching && condition.data == null; });
        }))();
};
var willError = function (conditions) {
    return ((conditions === null || conditions === void 0 ? void 0 : conditions.error) ||
        (function () { return conditions === null || conditions === void 0 ? void 0 : conditions.find(function (condition) { return condition.isError; }); }))();
};
var authPermissionError = function (conditions) {
    return ((conditions === null || conditions === void 0 ? void 0 : conditions.error) ||
        (function () { return conditions === null || conditions === void 0 ? void 0 : conditions.find(function (condition) { var _a; return ((_a = condition.error) === null || _a === void 0 ? void 0 : _a.message) == "403"; }); }))();
};
export var willHold = function (conditions) {
    return willSpin(conditions) ||
        willError(conditions) ||
        authPermissionError(conditions);
};
var Spinner = function () { return (_jsxs(_Fragment, { children: [" ", _jsx(HDSpinner, { size: "large" }), " "] })); };
export var FloatingSpinner = function () {
    return useContext(SpinnerContext).shouldSpin ? (_jsx("div", __assign({ style: { padding: "0 10px", width: "50px", height: "50px" } }, { children: _jsx(Spinner, {}) }))) : (_jsx("div", __assign({ style: { width: "50px", height: "50px" } }, { children: "\u00A0" })));
};
var SpinnerAndErrorer = function (_a) {
    var conditions = _a.conditions, errorDescription = _a.errorDescription, sx = _a.sx, children = _a.children;
    var LoaderContainer = styled(Box)(__assign({ position: "relative", display: "flex", flexDirection: "row", left: "calc(50% - 80px)", top: "30px" }, sx));
    if (willSpin(conditions)) {
        return (_jsx(LoaderContainer, { children: _jsx(Spinner, {}) }));
    }
    else if (willError(conditions)) {
        var permissionError = authPermissionError(conditions)
            ? "Error 403 - Forbidden: Access Denied"
            : errorDescription || "There was an error loading data.";
        return (_jsxs(LoaderContainer, { children: [" ", _jsx(LoadError, { errorDescription: permissionError, onClick: function () { return window.location.reload(); } })] }));
    }
    return _jsx(_Fragment, { children: children });
};
export default SpinnerAndErrorer;
