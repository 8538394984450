var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useContext } from "react";
import { FilterContext } from "../providers/FilterProvider";
export function useScoreThreshold(_a) {
    var _b, _c, _d, _e, _f, _g;
    var scoreType = _a.scoreType, useThreshold = _a.useThreshold, useScores = _a.useScores, scoreKey = _a.scoreKey;
    var getFilters = useContext(FilterContext).getFilters;
    var data = useThreshold({ scoreType: scoreType }).data;
    var scores = useScores({
        filters: __assign(__assign({}, getFilters()), { scoreType: scoreType }),
    });
    var _h = ((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.score_type_threshold) || [
        0, 0,
    ], low = _h[0], high = _h[1];
    var cell = (_f = (_e = (_d = (_c = scores === null || scores === void 0 ? void 0 : scores.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.functions[0]) === null || _e === void 0 ? void 0 : _e.sub_functions) === null || _f === void 0 ? void 0 : _f[0];
    var action = (_g = cell === null || cell === void 0 ? void 0 : cell[scoreKey]) === null || _g === void 0 ? void 0 : _g.state;
    return [low, high, action];
}
