import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useGetLeaderedSuccessionOverviewQuery, useGetLeaderedSuccessionScoresQuery, useGetLeaderedSuccessionThresholdsQuery, } from "../../store/api/core";
import { ScoreBreakdown } from "../../components/Summary/Summary";
import HeatmappedPage from "../../components/HeatmappedPage/HeatmappedPage";
import StatusInfoPopUp from "../StatusInfoPopUp/StatusInfoPopUp";
import { Status } from "../../common/action";
import { LowPerformerLevelFunctionMatrix, percentageOfScoreFormatterBase, } from "../LevelFunctionMatrix/Variants";
import { useHeatmapLeaderedSuccessionScores } from "../../hooks/heatmapScores";
import { updateFilters, } from "../../providers/FilterProvider";
import { getRoute } from "../../routes/getAllRoutes";
import { LeaderedSuccessionTypes, LeaderedSuccessionLabelTypes, } from "../../store/api/types/core";
import { PercentageLegend } from "../Legend/Legend";
import { useNavigate } from "../../hooks/navigate";
var percentageOfScoreFormatter = percentageOfScoreFormatterBase;
var LocalScoreBreakdown = function () {
    var _a, _b;
    var overview = useGetLeaderedSuccessionOverviewQuery({});
    var summaryStatus = (overview === null || overview === void 0 ? void 0 : overview.isSuccess)
        ? (_b = (_a = overview === null || overview === void 0 ? void 0 : overview.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.state
        : "No data";
    var summaryRightSideText = summaryStatus == Status.Act
        ? "This dashboard shows leader mobility risks. The results indicate that a high number of " +
            "leaders are at risk. Explore the heatmap, by group and metric, to identify leaders at " +
            "greatest risk that require immediate intervention."
        : summaryStatus == Status.Watch
            ? "This dashboard shows leader mobility risks. The results indicate that a notable number " +
                "of leaders are at risk. Explore the heatmap, by group and metric, to identify leaders " +
                "that are at high risk for additional follow up."
            : summaryStatus == Status.Maintain
                ? "This dashboard shows leader mobility risks. The results indicate that a relatively " +
                    "small number of leaders are at risk. Explore the heatmap, by group and metric, to " +
                    "proactively identify if any leaders are at high risk."
                : "No data";
    return (_jsx(ScoreBreakdown, { action: summaryStatus, summaryRightSideText: summaryRightSideText, title: "Organization Overview", showDescriptions: true }));
};
var LeaderedSuccession = function () {
    var navigate = useNavigate();
    var onClickIndividualRisk = function (leader) {
        var filterOptions = {
            roleProfileId: [leader.role_id],
            subFunctionFilter: [leader.sub_function],
            level: [leader.level],
        };
        updateFilters(filterOptions);
        navigate(getRoute("MobilityAll"));
    };
    var overviewComponentInfo = {
        inside: _jsx(LocalScoreBreakdown, {}),
    };
    var descriptionPopup = (_jsx(StatusInfoPopUp, { description: "Please note the thresholds differ based on the metric selected.", popupText: {
            act: "A high number of leaders possess mobility risks. Recommend taking action with " +
                "leaders at greatest risk.",
            watch: "A notable number of leaders possess mobility risks. Recommend additional review of " +
                "leaders at high risk.",
            maintain: "A relatively small number of leaders possess mobility risks. While risk is generally " +
                "low, proactive monitoring or review is still recommended.",
        } }));
    var SuccessionLowPerformerLevelFunctionMatrix = function (_a) {
        var scoreType = _a.scoreType;
        var highRiskTitleFormatter = function (_a) {
            var name = _a.name;
            return "".concat(name || "unknown name");
        };
        return (_jsx(LowPerformerLevelFunctionMatrix, { scoreType: scoreType, useScores: useHeatmapLeaderedSuccessionScores, highRiskTitleFormatter: highRiskTitleFormatter, highRiskTitle: {
                teams: "Teams with most leaders at risk",
                individual: "Roles with most leaders at risk",
            }, secondaryPageUrl: getRoute("MobilityAll"), onClickIndividualRisk: onClickIndividualRisk }));
    };
    var heatmaps = [
        {
            label: LeaderedSuccessionLabelTypes.MobilityRisk,
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: LeaderedSuccessionTypes.MobilityRisk })),
            legend: (_jsx(PercentageLegend, { label: LeaderedSuccessionLabelTypes.MobilityRisk, scoreType: LeaderedSuccessionTypes.MobilityRisk, scoreKey: LeaderedSuccessionTypes.MobilityRisk, useScores: useGetLeaderedSuccessionScoresQuery, useThreshold: useGetLeaderedSuccessionThresholdsQuery, isDescending: true, descriptionPopup: descriptionPopup })),
            scoreKey: LeaderedSuccessionTypes.MobilityRisk,
            scoreFormatter: percentageOfScoreFormatter,
            description: "Leaders who are at risk due to an ambiguous or undefined career pathway.",
        },
        {
            label: LeaderedSuccessionLabelTypes.NoPathForward,
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: LeaderedSuccessionTypes.NoPathForward })),
            legend: (_jsx(PercentageLegend, { label: LeaderedSuccessionLabelTypes.NoPathForward, scoreKey: LeaderedSuccessionTypes.NoPathForward, useScores: useGetLeaderedSuccessionScoresQuery, scoreType: LeaderedSuccessionTypes.NoPathForward, useThreshold: useGetLeaderedSuccessionThresholdsQuery, isDescending: true, descriptionPopup: descriptionPopup })),
            scoreKey: LeaderedSuccessionTypes.NoPathForward,
            scoreFormatter: percentageOfScoreFormatter,
            description: "Leaders who are not currently included in any succession plan or pool.",
        },
        {
            label: LeaderedSuccessionLabelTypes.OnMultiplePipelines,
            implementation: (_jsx(SuccessionLowPerformerLevelFunctionMatrix, { scoreType: LeaderedSuccessionTypes.OnMultiplePipelines })),
            legend: (_jsx(PercentageLegend, { label: LeaderedSuccessionLabelTypes.OnMultiplePipelines, scoreKey: LeaderedSuccessionTypes.OnMultiplePipelines, useScores: useGetLeaderedSuccessionScoresQuery, scoreType: LeaderedSuccessionTypes.OnMultiplePipelines, useThreshold: useGetLeaderedSuccessionThresholdsQuery, isDescending: true, descriptionPopup: descriptionPopup })),
            scoreKey: LeaderedSuccessionTypes.OnMultiplePipelines,
            scoreFormatter: percentageOfScoreFormatter,
            description: "Leaders who are part of three or more succession plans or pools.",
        },
    ];
    return (_jsx(HeatmappedPage, { heatmaps: heatmaps, useScores: useGetLeaderedSuccessionScoresQuery, heading: overviewComponentInfo, useOverview: useGetLeaderedSuccessionOverviewQuery, countText: "leader" }));
};
export default LeaderedSuccession;
